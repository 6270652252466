<template>
  <br/>
  <div style="padding:5px"  class="row">
    <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
        <label for="per-page" class="form-label me-2 mt-2">Per page</label>
        <select id="per-page" class="form-control" v-model="perPage" @click="perPageFilter(perPage)">
          <option value="10" :value="perPage">10</option>
          <option value="20" :value="perPage">20</option>
          <option value="50" :value="perPage">50</option>
        </select>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12  mb-3">
      <label for="category" class="form-label me-2 mt-2">Content category</label>
      <select class="form-control" id="category" v-model="category" @click="changeCategory(category)">
        <option value="0" :value="category">
          All
        </option>
        <option v-for="contentCategory in contentCategories" :value="contentCategory.id">
          {{contentCategory.name}}
        </option>
      </select>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
      <label for="per-page" class="form-label me-2 mt-2">Type</label>
      <select id="per-page" class="form-control" v-model="type" @click="changeStatus(type)">
        <option value="0" :value="type">MRR</option>
        <option value="1" :value="type">One time-tips</option>
        <option value="2" :value="type">Subscriptions</option>
        <option value="3" :value="type">Pay per-post</option>
        <option value="4" :value="type">Fundraiser</option>
        <option value="5" :value="type">Merchant e-shop</option>
        <option value="7" :value="type">Pay per-category</option>
        <option value="8" :value="type">Pay per-content subscription</option>
        <option value="9" :value="type">Subscription upgrade</option>
      </select>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
      <label for="currency" class="form-label me-2 mt-2">Currency</label>
      <select class="form-control" id="currency" v-model="selectedCurrency" @click="changeCurrency(selectedCurrency)">
        <option v-for="currency in currencies" :value="currency.currency">{{currency.currency}}</option>
      </select>
    </div>
  </div>

  <div class="table-responsive">
    <table class="table">
      <thead>
      <tr>
        <th scope="col">Place</th>
        <th scope="col">Id</th>
        <th scope="col">Creator name, slug</th>
        <th scope="col">Amount</th>
        <th scope="col">Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="channels.length > 0" v-for="(channel, index) in channels" :class="{
        'first-place': currentPage === 1 && index === 0,
        'second-place': currentPage === 1 && index === 1,
        'third-place': currentPage === 1 && index === 2}">
        <td>{{index + 1}}</td>
        <td>{{channel.id}}</td>
        <td>
          <p>{{channel.name}}</p>
          <a :href="channel.link">{{channel.slug}}</a>
        </td>
        <td>{{channel.total_amount}} {{channel.currency}}</td>
        <td>
          <div class="btn-group-sm d-flex gap-3" role="group">
            <router-link :to="{name: 'SingleChannel', params: {id: channel.id}}" class="btn btn-info">View</router-link>
          </div>
        </td>
      </tr>
      <tr v-else-if="loading">
        <td colspan="7" class="text-center"><font-awesome-icon icon="fa-solid fa-sync" spin/></td>
      </tr>
      <tr v-else>
        <td colspan="7" class="text-center">No creators</td>
      </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-between" v-if="totalRecords >= 1">
      <div>
        <div class="p-2">
          <p>Total creators: {{ totalRecords }}</p>
        </div>
      </div>
      <div>
        <div class="p-2">
          <nav v-if="totalPages >= 1" aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item" :class="{disabled: currentPage - 1 < 1}">
                <a @click="paginate(currentPage - 1)" class="page-link">Previous</a>
              </li>
              <li class="page-item" :class="{active: 1 === currentPage}">
                <a @click="paginate(1)" class="page-link" :class="{'not-clickable': currentPage === 1}">1</a>
              </li>
              <li class="page-item" v-if="currentPage - 2 > 1">
                <a href="#" class="page-link">...</a>
              </li>
              <li class="page-item" v-if="currentPage - 2 > 1">
                <a @click="paginate(currentPage - 2)" class="page-link">{{ currentPage - 2}}</a>
              </li>
              <li class="page-item" v-if="currentPage - 1 > 1">
                <a @click="paginate(currentPage - 1)" class="page-link">{{ currentPage - 1}}</a>
              </li>
              <li class="page-item" v-if="currentPage !== 1"  :class="{active: currentPage}">
                <a @click="paginate(currentPage)" class="page-link" :class="{'not-clickable': currentPage}">{{currentPage }}</a>
              </li>
              <li class="page-item" v-if="currentPage + 1 <= totalPages">
                <a @click="paginate(currentPage + 1)" class="page-link">{{ currentPage + 1}}</a>
              </li>
              <li class="page-item" v-if="currentPage + 2 <= totalPages">
                <a @click="paginate(currentPage + 2)" class="page-link">{{ currentPage + 2}}</a>
              </li>
              <li class="page-item" v-if="currentPage + 2 < totalPages">
                <a href="#" class="page-link">...</a>
              </li>
              <li class="page-item" v-if="currentPage !== totalPages && currentPage + 2 < totalPages" :class="{active: totalPages === currentPage}">
                <a @click="paginate(totalPages)" class="page-link" :class="{'not-clickable': totalPages === currentPage}">{{totalPages }}</a>
              </li>
              <li class="page-item" :class="{disabled: currentPage + 1 > totalPages}">
                <a @click="paginate(currentPage + 1)" class="page-link">Next</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import _debounce from "lodash/debounce";

export default {
  data() {
    return {
      loading: true,
      channels: [],
      currencies: [],
      contentCategories: [],
      selectedCurrency: 'EUR',
      category: 0,
      type: 0,
      currentPage: 1,
      totalPages: 0,
      totalRecords: 0,
      perPage: 20
    }
  },

  mounted() {
    this.getTopChannels(1, 20, 0, 0, 'EUR');
    this.fetchCurrencies();
    this.fetchContentCategories();
  },

  methods: {
    debounceGetTopChannels: _debounce(function (page, perPage, type, category, currency) {
      this.loading = true;
      axios.get(`/admin/top-channels?page=${page}&per_page=${perPage}&type=${type}&category=${category}&currency=${currency}`)
          .then(response => {
        this.channels = response.data.data;
        this.totalPages = response.data.totalPages;
        this.totalRecords = response.data.totalRecords;
        this.loading = false;
      })
          .catch(error => {
            this.loading = false;
            console.log(error);
          });
    }, 500),
    getTopChannels(page, perPage, type, category, currency) {
      this.debounceGetTopChannels(page, perPage, type, category, currency);
    },
    fetchContentCategories() {
      axios.get(`/admin/content-categories`)
          .then(response => {
            this.contentCategories = response.data;
          })
          .catch(error => {
            console.log(error);
          });
    },
    fetchCurrencies() {
      axios.get(`/admin/currency/channels`)
          .then(response => {
            this.currencies = response.data.data;
          })
          .catch(error => {
            console.log(error);
          });
    },
    changeStatus(type) {
      this.type = type;
      this.getTopChannels(this.currentPage, this.perPage, this.type, this.category, this.selectedCurrency);
    },
    changeCategory(category) {
      this.category = category;
      this.getTopChannels(this.currentPage, this.perPage, this.type, this.category, this.selectedCurrency);
    },
    changeCurrency(currency) {
      this.selectedCurrency = currency;
      this.getTopChannels(this.currentPage, this.perPage, this.type, this.category, this.selectedCurrency);
    },
    perPageFilter(perPage) {
      this.perPage = perPage;
      this.getTopChannels(this.currentPage, this.perPage, this.type, this.category, this.selectedCurrency);
    },
    paginate(page) {
      this.currentPage = page;
      this.getTopChannels(this.currentPage, this.perPage, this.type, this.category, this.selectedCurrency);
    }
  }
}
</script>