<template>
  <div class="header-wrapper row m-0">
    <SearchBar />
    <Logo />

    <div class="left-header col-xxl-5 col-xl-6 col-lg-5 col-md-4 col-sm-3 p-0">
      <!-- <div class="notification-slider"> -->
      <swiper v-if="notifications > 0"  :slidesPerView="1" :autoplay="{
        delay: 2500,
        disableOnInteraction: false,
      }" :modules="modules" class="notification-slider" direction="vertical">
        <swiper-slide class="h-100">
          <div class="d-flex h-100">
            <img src="@/assets/images/giftools.gif" alt="gif">
            <h6 class="mb-0 f-w-400" style="width: auto">
                 <span v-if="role === 'Ukrainian Manager'"  class="badge text-bg-warning">
                <router-link :to="{name: 'UkrainianChannels', query: {status: 'pending_check'}}">
                You have to approve {{pendingChannels}} creators</router-link></span>
              <span v-else class="badge text-bg-warning">
                <router-link :to="{name: 'Channels', query: {status: 'pending_check'}}">
                You have to approve {{pendingChannels}} creators</router-link></span>
            </h6><i class="icon-arrow-top-right f-light"></i>
          </div>
        </swiper-slide>

      </swiper>
      <p v-else>No notifications</p>
      <!-- </div> -->
    </div>
    <div class="nav-right col-xxl-7 col-xl-6 col-md-7 col-8 pull-right right-header p-0 ms-auto">
      <ul class="nav-menus">

        <Profile />
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from "swiper";
import "swiper/css";
import Profile from './profile';
import Logo from './logo.vue';
import SearchBar from './search';
import axios from "axios";
import Notifications from "@/components/header/notifications.vue";
import store from "@/store";

export default {
  //   name: 'Search',
  components: {
    Notifications,
    Profile,
    Logo,
    Swiper,
    SwiperSlide,
    SearchBar,
  },
  data() {
    return {
      bookmark: false,
      pendingChannels: 0,
      notifications: 0,
      role: store.state.auth.user.role,
    };
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.searchData,
      megamenuItems: (state) => state.menu.megamenu,
      searchOpen: (state) => state.menu.searchOpen,
    }),
  },
  methods: {
    search_open() {
      this.$store.state.menu.searchOpen = true;
    },
    bookmark_open() {
      this.bookmark = !this.bookmark;
    },
    getPendingChannelCount() {
      const country = this.role === 'Ukrainian Manager' ? 'UA' : '';
      axios.get(`admin/channel/pending?country=${country}`)
          .then(response => {
            this.pendingChannels = response.data.pending_channels;
            if (this.pendingChannels > 0) {
            this.notifications++;
              }
          })
          .catch(error => {
            console.log(error);
          })
    }
  },
  created() {
    this.getPendingChannelCount();
  },
  setup() {
    return {
      modules: [Autoplay],
    };
  },
};
</script>
