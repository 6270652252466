import {createRouter, createWebHistory} from "vue-router";
import store from "@/store";
import Body from '../components/body';
import Login from "@/pages/auth/Login.vue";
import Error403 from "@/pages/error/Error403.vue";
import Error404 from "@/pages/error/Error404.vue";
import Users from "@/pages/dashboard/users/Users.vue";
import Dashboard from "@/pages/dashboard/Dashboard.vue"
import Channels from "@/pages/dashboard/channels/Channels.vue";
import Pledges from "@/pages/dashboard/pledges/Pledges.vue";
import Payments from "@/pages/dashboard/payments/Payments.vue";
import SingleUser from "@/pages/dashboard/users/SingleUser.vue";
import UserPledges from "@/pages/dashboard/pledges/UserPledges.vue";
import UserPayments from "@/pages/dashboard/payments/UserPayments.vue";
import UserOrders from "@/pages/dashboard/orders/UserOrders.vue"
import UnlockedUserPosts from "@/pages/dashboard/posts/UnlockedUserPosts.vue";
import ChannelPosts from "@/pages/dashboard/posts/ChannelPosts.vue";
import UnlockedUserCategories from "@/pages/dashboard/categories/UnlockedUserCategories.vue";
import SingleChannel from "@/pages/dashboard/channels/SingleChannel.vue";
import EditChannel from "@/pages/dashboard/channels/EditChannel.vue";
import Reports from "@/pages/dashboard/Reports.vue";
import SubCancelSurveys from "@/pages/dashboard/SubCancelSurveys.vue";
import EditUser from "@/pages/dashboard/users/EditUser.vue";
import TopChannels from "@/pages/dashboard/channels/TopChannels.vue";
import BlockedChannels from "@/pages/dashboard/users/BlockedChannels.vue";
import Logs from "@/pages/dashboard/logs/Logs.vue";
import Accounting from "@/pages/dashboard/payments/Accounting.vue";
import ContentCategories from '@/pages/dashboard/content-categories/ContentCategories.vue';


import UserManagement from "@/pages/dashboard/management/UserManagement.vue";
import AddUserManagement from "@/pages/dashboard/management/AddUserManagement.vue";
import UkrainianChannels from "@/pages/dashboard/channels/UkrainianChannels.vue";
import UkrainianPledges from "@/pages/dashboard/pledges/UkrainianPledges.vue";
import UkrainianPayments from "@/pages/dashboard/payments/UkrainianPayments.vue";
import UkrainianDashboard from "@/pages/dashboard/UkrainianDashboard.vue";
import TopUkraineChannels from "@/pages/dashboard/channels/TopUkraineChannels.vue";

const routes =[
    {
        path: '/',
        component: Body,
        children: [
          {
            path: '',
            name: 'Home',
            component: Dashboard,
            meta: { requiresAuth: true, roles: ['Admin', 'Super Admin']}
          },
            {
                path: 'users/',
                children: [
                    {
                        path: 'index',
                        name: 'Users',
                        meta: { requiresAuth: true, roles: ['Admin', 'Super Admin', 'Ukrainian Manager']},
                        component: Users,
                    },
                    {
                        path: ':id',
                        name: 'SingleUser',
                        meta: { requiresAuth: true, roles: ['Admin', 'Super Admin', 'Ukrainian Manager']},
                        component: SingleUser,
                    },
                    {
                        path: 'edit/:id',
                        name: 'EditUser',
                        meta: { requiresAuth: true, roles: ['Admin', 'Super Admin']},
                        component: EditUser,
                    },
                    {
                        path: 'blocked-channels/:id',
                        name: 'BlockedChannels',
                        meta: { requiresAuth: true, roles: ['Admin', 'Super Admin', 'Ukrainian Manager']},
                        component: BlockedChannels,
                    },
                    {
                        path: 'unlocked-posts/:id',
                        name: 'UnlockedUserPosts',
                        meta: { requiresAuth: true, roles: ['Admin', 'Super Admin', 'Ukrainian Manager']},
                        component: UnlockedUserPosts
                    },
                    {
                        path: 'unlocked-categories/:id',
                        name: 'UnlockedUserCategories',
                        meta: { requiresAuth: true, roles: ['Admin', 'Super Admin', 'Ukrainian Manager']},
                        component: UnlockedUserCategories
                    }
                ]
            },
            {
                path: 'creators/',
                children: [
                    {
                        path: 'index',
                        name: 'Channels',
                        meta: { requiresAuth: true, roles: ['Admin', 'Super Admin']},
                        component: Channels,
                    },
                    {
                        path: ':id',
                        name: 'SingleChannel',
                        meta: { requiresAuth: true, roles: ['Admin', 'Super Admin', 'Ukrainian Manager']},
                        component: SingleChannel,
                    },
                    {
                        path: 'edit/:id',
                        name: 'EditChannel',
                        meta: { requiresAuth: true, roles: ['Admin', 'Super Admin']},
                        component: EditChannel,
                    },
                    {
                        path: 'posts/:id',
                        name: 'ChannelPosts',
                        meta: { requiresAuth: true, roles: ['Admin', 'Super Admin', 'Ukrainian Manager']},
                        component: ChannelPosts
                    }
                ]

            },
            {
                path: 'subscriptions/',
                children: [
                    {
                        path: '',
                        name: 'Pledges',
                        meta: { requiresAuth: true, roles: ['Admin', 'Super Admin']},
                        component: Pledges,
                    },
                    {
                        path: 'user/:id',
                        name: 'UserPledges',
                        meta: { requiresAuth: true, roles: ['Admin', 'Super Admin', 'Ukrainian Manager']},
                        component: UserPledges,
                    },
                ]
            },
            {
                path: 'payments/',
                children: [
                    {
                        path: '',
                        name: 'Payments',
                        meta: { requiresAuth: true, roles: ['Admin', 'Super Admin']},
                        component: Payments,
                    },
                    {
                        path: 'user/:id',
                        name: 'UserPayments',
                        meta: { requiresAuth: true, roles: ['Admin', 'Super Admin','Ukrainian Manager']},
                        component: UserPayments,
                    },
                ]
            },
            {
                path: 'orders/',
                children: [
                    {
                        path: 'user/:id',
                        name: 'UserOrders',
                        meta: { requiresAuth: true, roles: ['Admin', 'Super Admin','Ukrainian Manager']},
                        component: UserOrders,
                    },
                ]
            },
            {
                path: 'reports',
                meta: { requiresAuth: true, roles: ['Admin', 'Super Admin', 'Ukrainian Manager']},
                name: 'Reports',
                component: Reports
            },
            {
                path: 'logs',
                meta: { requiresAuth: true, roles: ['Super Admin']},
                name: 'Logs',
                component: Logs
            },
            {
                path: 'accounting',
                meta: { requiresAuth: true, roles: ['Super Admin']},
                name: 'Accounting',
                component: Accounting
            },
            {
                path: 'surveys',
                meta: { requiresAuth: true, roles: ['Admin', 'Super Admin', 'Ukrainian Manager']},
                name: 'Surveys',
                component: SubCancelSurveys
            },
            {
                path: 'top-creators',
                meta: { requiresAuth: true, roles: ['Admin', 'Super Admin']},
                name: 'TopChannels',
                component: TopChannels
            },
            {
                path: 'user-management/',
                meta: { requiresAuth: true, roles: ['Super Admin']},
                children: [
                    {
                        path: '',
                        name: 'UserManagement',
                        component: UserManagement
                    },
                    {
                        path: 'add',
                        name: 'AddUserManagement',
                        component: AddUserManagement
                    }
                ]
            },
            {
                path: 'content-categories',
                meta: { requiresAuth: true, roles: ['Admin', 'Super Admin']},
                name: 'ContentCategories',
                component: ContentCategories
            },
            {
                path: 'ukrainian/',
                meta: { requiresAuth: true, roles: ['Admin', 'Super Admin', 'Ukrainian Manager']},
                children: [
                    {
                        path: '/pledges',
                        name: 'UkrainianPledges',
                        component: UkrainianPledges
                    },
                    {
                        path: 'payments',
                        name: 'UkrainianPayments',
                        component: UkrainianPayments,
                    },
                    {
                        path: 'channels',
                        name: 'UkrainianChannels',
                        component: UkrainianChannels,
                    },
                    {
                        path: 'dashboard',
                        name: 'UkrainianHome',
                        component: UkrainianDashboard
                    },
                    {
                        path: 'top-creators',
                        name: 'TopUkraineChannels',
                        component: TopUkraineChannels
                    }
                ]
            },
        ],
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/403',
        name: '403',
        component: Error403
    },
    {
        path: '/404',
        name: '404',
        component: Error404
    }
]


const router=createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('token');
    const user = store.state.auth.user;
    const userRoles = user ? [user.role] : [];
    console.log(userRoles);
    const meta = to.meta || {};
    meta.metaTags = [
        {
            name: 'robots',
            content: 'noindex',
        },
    ];
    document.title = to.name;

    if (to.path === '/login') {
        if (token && (userRoles.includes('Admin') || userRoles.includes('Super Admin'))) {
            next({ path: '/' }); // Redirect to the home page if the user is already logged in.
        }
        else if (token && userRoles.includes('Ukrainian Manager')) {
            next({path: '/ukrainian/dashboard'});
        }
        else {
            next(); // Allow access to the login page.
        }
    } else if (to.path === '/') {
        if (token && userRoles.includes('Ukrainian Manager')) {
            next({ path: '/ukrainian/dashboard' }); // Redirect to the home page if the user is already logged in.
        } else if(token && (userRoles.includes('Admin') || userRoles.includes('Super Admin'))) {
            next();
        } else {
            next({path: '/login'});
        }
    }
    else if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!token) {
            next({
                path: '/login',
            });
        } else if (!user || !hasAccess(userRoles, to.meta.roles)) {
            next({
                path: '/403',
            });
        } else {
            next();
        }
    } else {
        next();
    }
});
//In this updated code, if the user is already on the login page and has a token, they are redirected to the home page. If they don't have a token, they are allowed to access the login page. This should prevent the infinite redirection loop.







function hasAccess(userRoles, requiredRoles) {
    return userRoles.some(role => requiredRoles.includes(role));
}

export default router