<template>
  <br/>
  <div class="card col-lg-6 container-fluid justify-content-center">
    <form @submit.prevent="updateChannel">
    <div class="card-body">
      <h4 class="text-center">Edit {{ channel.name ?? 'Name'}} channel</h4>
      <div class="accordion" id="accordionChannel">
        <div class="accordion-item">
          <h2 class="accordion-header"  id="headingChannelOne">
            <button class="accordion-button collapsed" :class="{'text-danger': validationChannelErrors.name || validationChannelErrors.slug}" type="button" data-bs-toggle="collapse" data-bs-target="#channelOne" aria-expanded="false" aria-controls="channelOne">
              General info
            </button>
          </h2>
          <div id="channelOne" class="accordion-collapse collapse" aria-labelledby="headingChannelOne" data-bs-parent="#accordionChannel">
            <div class="accordion-body">
                <div class="mb-3">
                  <label for="name" class="form-label">Name</label>
                  <input type="text" class="form-control" id="name" v-model="channel.name">
                  <p v-if="validationChannelErrors" v-for="names in validationChannelErrors.name" class="text-danger">{{ names}}</p>
                </div>
                <div class="mb-3">
                  <label for="slug" class="form-label">Slug</label>
                  <input type="text" class="form-control" id="slug" v-model="channel.slug">
                  <p v-if="validationChannelErrors" v-for="slugs in validationChannelErrors.slug" class="text-danger">{{ slugs}}</p>
                </div>
                <div class="mb-3">
                  <label for="category" class="form-label">Content category</label>
                  <select class="form-control" id="category" v-model="channel.category_id">
                    <option v-for="contentCategory in contentCategories" :value="contentCategory.id">
                      {{contentCategory.name}}
                    </option>
                  </select>
                </div>
                <div class="mb-3">
                  <label for="status" class="form-label me-2 mt-2">Status</label>
                  <select id="status" class="form-control" v-model="channel.status">
                    <option value="awaiting_confirmation" :value="channel.status">Awaiting confirmation</option>
                    <option value="pending_check" :value="channel.status">Pending check</option>
                    <option value="OK" :value="channel.status">OK</option>
                    <option value="denied" :value="channel.status">Denied</option>
                  </select>
                </div>
                <div class="mb-3">
                  <label for="adult" class="form-label">Adult</label>
                  <select class="form-control" id="adult" v-model="channel.adult">
                    <option value="true" :value="channel.adult">
                      Yes
                    </option>
                    <option value="false" :value="channel.adult">
                      No
                    </option>
                  </select>
                </div>
                <div class="mb-3">
                  <label for="searchable" class="form-label">Searchable</label>
                  <select class="form-control" id="searchable" v-model="channel.searchable">
                    <option value="true" :value="channel.searchable">
                      Yes
                    </option>
                    <option value="false" :value="channel.searchable">
                      No
                    </option>
                  </select>
                </div>
                <div class="mb-3">
                  <label for="frozen" class="form-label">Frozen</label>
                  <select class="form-control" id="frozen" v-model="channel.frozen">
                    <option value="true" :value="channel.frozen">
                      Yes
                    </option>
                    <option value="false" :value="channel.frozen">
                      No
                    </option>
                  </select>
                </div>
                <div class="mb-3">
                  <label for="featured" class="form-label">Show creator in landing page</label>
                  <select class="form-control" id="featured" v-model="channel.featured">
                    <option value="1" :value="channel.featured">
                      Yes
                    </option>
                    <option value="0" :value="channel.featured">
                      No
                    </option>
                  </select>
                </div>
                <div class="mb-3">
                  <label for="c-listed" class="form-label">Show creator in explore page</label>
                  <select class="form-control" id="c-listed" v-model="channel.c_listed">
                    <option value="true" :value="channel.c_listed">
                      Yes
                    </option>
                    <option value="false" :value="channel.c_listed">
                      No
                    </option>
                  </select>
                </div>
            </div>
          </div>
        </div>
      </div>
      <br/>

      <div class="alert alert-success" role="alert" v-if="successChannel">
        {{ successChannel}}
      </div>

      <div class="text-center">
        <button type="submit" class="btn btn-dark-purple">Submit</button>
      </div>
    </div>
    </form>
  </div>
  <div class="card col-lg-6 container-fluid justify-content-center" id="ckeditor">
    <form @submit.prevent="updateChannelSettings" novalidate>
    <div class="card-body">
      <h4 class="text-center">Edit channel {{ channel.name ?? 'Name'}} settings</h4>
      <div class="accordion" id="accordionChannelSettings">
        <div class="accordion-item">
          <h2 class="accordion-header" id="channelSettingsHeadingsOne">
            <button class="accordion-button collapsed"   :class="{'text-danger': validationChannelSettingsErrors.twitch_url ||
              validationChannelSettingsErrors.youtube_url ||
              validationChannelSettingsErrors.twitch_url ||
              validationChannelSettingsErrors.github_url ||
              validationChannelSettingsErrors.instagram_url ||
              validationChannelSettingsErrors.facebook_url}" type="button" data-bs-toggle="collapse" data-bs-target="#channelSettingsOne" aria-expanded="false" aria-controls="channelsSettingsOne">
              Social links
            </button>
          </h2>
          <div id="channelSettingsOne" class="accordion-collapse collapse" aria-labelledby="channelSettingsHeadingsOne" data-bs-parent="#accordionChannelSettings">
            <div class="accordion-body">
                <div class="mb-3">
                  <label for="facebook" class="form-label">Facebook URL</label>
                  <input type="text" class="form-control" id="facebook" v-model="channel.settings.facebook_url">
                  <p v-if="validationChannelSettingsErrors" v-for="facebook_url in validationChannelSettingsErrors.facebook_url" class="text-danger">{{ facebook_url}}</p>
                </div>
                <div class="mb-3">
                  <label for="twitter" class="form-label">Twitter URL</label>
                  <input type="text" class="form-control" id="twitter" v-model="channel.settings.twitter_url">
                  <p v-if="validationChannelSettingsErrors" v-for="twitter_url in validationChannelSettingsErrors.twitter_url" class="text-danger">{{ twitter_url}}</p>
                </div>
                <div class="mb-3">
                  <label for="linkedin" class="form-label">LinkedIn URL</label>
                  <input type="text" class="form-control" id="linkedin" v-model="channel.settings.linkedin_url">
                  <p v-if="validationChannelSettingsErrors" v-for="linkedin_url in validationChannelSettingsErrors.linkedin_url" class="text-danger">{{ linkedin_url}}</p>
                </div>
                <div class="mb-3">
                  <label for="youtube" class="form-label">Youtube URL</label>
                  <input type="text" class="form-control" id="youtube" v-model="channel.settings.youtube_url">
                  <p v-if="validationChannelSettingsErrors" v-for="youtube_url in validationChannelSettingsErrors.youtube_url" class="text-danger">{{ youtube_url}}</p>
                </div>
                <div class="mb-3">
                  <label for="twitch" class="form-label">Twitch URL</label>
                  <input type="text" class="form-control" id="twitch" v-model="channel.settings.twitch_url">
                  <p v-if="validationChannelSettingsErrors" v-for="twitch_url in validationChannelSettingsErrors.twitch_url" class="text-danger">{{ twitch_url}}</p>
                </div>
                <h6 class="text-center"></h6>
                <div class="mb-3">
                  <label for="github" class="form-label">GitHub URL</label>
                  <input type="text" class="form-control" id="github" v-model="channel.settings.github_url">
                  <p v-if="validationChannelSettingsErrors" v-for="github_url in validationChannelSettingsErrors.github_url" class="text-danger">{{ github_url}}</p>
                </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="channelSettingsHeadingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Privacy settings
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="channelSettingsHeadingTwo" data-bs-parent="#accordionChannelSettings">
            <div class="accordion-body">
              <div class="mb-3">
                <label for="public-earnings" class="form-label">Public earnings</label>
                <select class="form-control" id="public-earnings" v-model="channel.settings.public_earnings">
                  <option value="true" :value="channel.settings.public_earnings">
                    Yes
                  </option>
                  <option value="false" :value="channel.settings.public_earnings">
                    No
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label for="public-subscribers" class="form-label">Public subscribers</label>
                <select class="form-control" id="public-subscribers" v-model="channel.settings.public_subscribers">
                  <option value="true" :value="channel.settings.public_subscribers">
                    Yes
                  </option>
                  <option value="false" :value="channel.settings.public_subscribers">
                    No
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label for="enable-onetime" class="form-label">Enable onetime</label>
                <select class="form-control" id="enable-onetime" v-model="channel.settings.enable_onetime">
                  <option value="true" :value="channel.settings.enable_onetime">
                    Yes
                  </option>
                  <option value="false" :value="channel.settings.enable_onetime">
                    No
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label for="auth-onetime" class="form-label">Auth onetime</label>
                <select class="form-control" id="auth-onetime" v-model="channel.settings.auth_onetime">
                  <option value="true" :value="channel.settings.auth_onetime">
                    Yes
                  </option>
                  <option value="false" :value="channel.settings.auth_onetime">
                    No
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label for="chat-with-non-subscribers" class="form-label">Chat with non subscribers</label>
                <select class="form-control" id="chat-with-non-subscribers" v-model="channel.settings.chat_with_non_subscribers">
                  <option value="true" :value="channel.settings.chat_with_non_subscribers">
                    Yes
                  </option>
                  <option value="false" :value="channel.settings.chat_with_non_subscribers">
                    No
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label for="one-timer-window-first" class="form-label">One timer window first</label>
                <select class="form-control" id="one-timer-window-first" v-model="channel.settings.onetimer_window_first">
                  <option value="true" :value="channel.settings.onetimer_window_first">
                    Yes
                  </option>
                  <option value="false" :value="channel.settings.onetimer_window_first">
                    No
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label for="enable-one-timer-buttons" class="form-label">Enable one timer buttons</label>
                <select class="form-control" id="enable-one-timer-buttons" v-model="channel.settings.enable_onetimer_buttons">
                  <option value="true" :value="channel.settings.enable_onetimer_buttons">
                    Yes
                  </option>
                  <option value="false" :value="channel.settings.enable_onetimer_buttons">
                    No
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="channelSettingsHeadingThree">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#channelSettingsThree" aria-expanded="false" aria-controls="channelSettingsThree">
              One timer settings
            </button>
          </h2>
          <div id="channelSettingsThree" class="accordion-collapse collapse" aria-labelledby="channelSettingsHeadingThree" data-bs-parent="#accordionChannelSettings">
            <div class="accordion-body">
              <div class="mb-3">
                <label for="onetimer-icon" class="form-label">One timer icon</label>
               <select class="form-control" id="onetimer-icon" v-model="channel.settings.onetime_icon">
                  <option style="color:black; width:80px; height:80px" id="fa-apple-alt" value="apple" :value="channel.settings.onetime_icon">
                     Apple
                  </option>
                  <option style="color:black; width:80px; height:80px" id="fa-mug-hot" value="coffee" :value="channel.settings.onetime_icon">
                     Coffee
                  </option>
                  <option style="color:black; width:80px; height:80px" id="fa-pizza-slice" value="donut" :value="channel.settings.onetime_icon">
                    Donut
                  </option>
                  <option style="color:black; width:80px; height:80px"  id="fa-euro-sign" value="euro" :value="channel.settings.onetime_icon">
                     Euro
                  </option>
                  <option style="color:black; width:80px; height:80px" id="fa-coins" value="coin" :value="channel.settings.onetime_icon">
                    Coin
                  </option>
                  <option style="color:black; width:80px; height:80px" id="fa-microphone-alt" value="podcast" :value="channel.settings.onetime_icon">
                    Podcast
                  </option>
                  <option style="color:black; width:80px; height:80px" id="fa-basketball-ball" value="basketball" :value="channel.settings.onetime_icon">
                    Basketball
                  </option>
                  <option style="color:black; width:80px; height:80px" id="fa-futbol" value="football" :value="channel.settings.onetime_icon">
                    Football
                  </option>
                  <option style="color:black; width:80px; height:80px" id="fa-cocktail" value="cocktail" :value="channel.settings.onetime_icon">
                    Cocktail
                  </option>
                  <option style="color:black; width:80px; height:80px" id="fa-bone" value="bone" :value="channel.settings.onetime_icon">
                    Bone
                  </option>
                  <option style="color:black; width:80px; height:80px"  id="fa-heart" value="heart" :value="channel.settings.onetime_icon">
                    Heart
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label for="first-tip-price" class="form-label">First tip price</label>
                <input type="number" min="5" class="form-control" id="first-tip-price" v-model="channel.settings.first_tip_price">
              </div>
              <div class="mb-3">
                <label for="second-tip-price" class="form-label">Second tip price</label>
                <input type="number" min="10" class="form-control" id="second-tip-price" v-model="channel.settings.second_tip_price">
              </div>
              <div class="mb-3">
                <label for="third-tip-price" class="form-label">Third tip price</label>
                <input type="number" min="20" class="form-control" id="third-tip-price" v-model="channel.settings.third_tip_price">
              </div>
              <div class="mb-3">
                <label for="post-one-timer-button" class="form-label">Post one timer button</label>
                <select class="form-control" id="post-one-timer-button" v-model="channel.settings.post_onetimer_button">
                  <option value="true" :value="channel.settings.post_onetimer_button">
                    Yes
                  </option>
                  <option value="false" :value="channel.settings.post_onetimer_button">
                    No
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header"  id="channelSettingsHeadingFour">
            <button class="accordion-button collapsed" :class="{'text-danger': validationChannelSettingsErrors.about_me ||
           validationChannelSettingsErrors.onetime_description ||
           validationChannelSettingsErrors.welcome_subscriber_message}" type="button" data-bs-toggle="collapse" data-bs-target="#channelSettingsFour" aria-expanded="false" aria-controls="channelSettingsFour">
              Description settings
            </button>
          </h2>
          <div id="channelSettingsFour" class="accordion-collapse collapse" aria-labelledby="channelSettingsHeadingFour" data-bs-parent="#accordionChannelSettings">
            <div class="accordion-body">
              <div class="mb-3">
                <label for="about-me" class="form-label">About me</label>
                <quill-editor id="edit" theme="snow" content-type="html" :content="channel.settings.about_me" v-model="channel.settings.about_me"></quill-editor>
                <p v-if="validationChannelSettingsErrors" v-for="about_me in validationChannelSettingsErrors.about_me" class="text-danger">{{ about_me}}</p>
              </div>
              <div class="mb-3">
                <label for="welcome-subscriber-message" class="form-label">Welcome subscriber message</label>
                <input type="text" class="form-control" id="welcome-subscriber-message" v-model="channel.settings.welcome_subscriber_message">
                <p v-if="validationChannelSettingsErrors" v-for="welcome_subscriber_message in validationChannelSettingsErrors.welcome_subscriber_message" class="text-danger">
                  {{ welcome_subscriber_message}}
                </p>
              </div>
              <div class="mb-3">
                <label for="onetime-description" class="form-label">One time description</label>
                <textarea rows="8" cols="8" class="form-control" id="onetime-description" v-model="channel.settings.onetime_description"></textarea>
                <p v-if="validationChannelSettingsErrors" v-for="onetime_description in validationChannelSettingsErrors.onetime_description" class="text-danger">{{ onetime_description}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="channelSettingsHeadingFive">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#channelSettingsFive" aria-expanded="false" aria-controls="channelSettingsFive">
              Other settings
            </button>
          </h2>
          <div id="channelSettingsFive" class="accordion-collapse collapse" aria-labelledby="channelSettingsHeadingFive" data-bs-parent="#accordionChannelSettings">
            <div class="accordion-body">
              <div class="mb-3">
                <label for="plyr" class="form-label">Use Contribee player</label>
                <select class="form-control" id="plyr" v-model="channel.settings.plyr">
                  <option value="true" :value="channel.settings.plyr">
                    Yes
                  </option>
                  <option value="false" :value="channel.settings.plyr">
                    No
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label for="rss-on" class="form-label">RSS links for audio posts</label>
                <select class="form-control" id="rss-on" v-model="channel.settings.rss_on">
                  <option value="true" :value="channel.settings.rss_on">
                    Yes
                  </option>
                  <option value="false" :value="channel.settings.rss_on">
                    No
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label for="copy-text" class="form-label">Copy text in posts</label>
                <select class="form-control" id="copy-text" v-model="channel.settings.copy_text">
                  <option value="true" :value="channel.settings.copy_text">
                    Yes
                  </option>
                  <option value="false" :value="channel.settings.copy_text">
                    No
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <br/>

        <div class="alert alert-success" role="alert" v-if="successChannelSettings">
        {{successChannelSettings}}
      </div>

        <div class="text-center">
          <button type="submit" class="btn btn-dark-purple">Submit</button>
        </div>
      </div>
    </div>
    </form>
  </div>
  <div class="card col-lg-6 container-fluid justify-content-center">
    <form @submit.prevent="changeChannelOwnership">
      <div class="card-body">
        <h4 class="text-center">Change {{ channel.name ?? 'Name'}} channel ownership</h4>
        <div class="accordion" id="accordionChannelChangeOwnership">
          <div class="accordion-item">
            <h2 class="accordion-header"  id="headingChannelChangeOwnership">
              <button class="accordion-button collapsed" :class="{'text-danger': validationChannelOwnershipErrors.email}" type="button" data-bs-toggle="collapse" data-bs-target="#channelChangeOwnership" aria-expanded="false" aria-controls="channelChangeOwnership">
                Change creator ownership
              </button>
            </h2>
            <div id="channelChangeOwnership" class="accordion-collapse collapse" aria-labelledby="headingChannelChangeOwnership" data-bs-parent="#accordionChannelChangeOwnership">
              <div class="accordion-body">
                <div class="mb-3">
                  <label for="user" class="form-label">User email</label>
                  <input class="form-control" id="user" v-model="channel.email"/>
                  <p v-if="validationChannelOwnershipErrors" v-for="emails in validationChannelOwnershipErrors.email" class="text-danger">{{ emails}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br/>

        <div class="alert alert-success" role="alert" v-if="successChannelChangeOwnerhsip">
          {{ successChannelChangeOwnerhsip}}
        </div>

        <div class="text-center">
          <button type="submit" class="btn btn-dark-purple">Submit</button>
        </div>
      </div>
    </form>
  </div>
  <div class="card col-lg-6 container-fluid justify-content-center">
    <form @submit.prevent="updateChannelUploadLimits">
      <div class="card-body">
        <h4 class="text-center">Change {{ channel.name ?? 'Name'}} creator upload limits (mb)</h4>
        <div class="input-group mb-3">
          <input id="upload-limits" type="number" min="500" v-model="channel.upload_limits" class="form-control" placeholder="500" aria-label="upload-limits" aria-describedby="basic-addon2">
          <span class="input-group-text" id="basic-addon2">MB</span>
        </div>
        <br/>
        <div class="alert alert-success" role="alert" v-if="successChannelUploadLimit">
          {{ successChannelUploadLimit}}
        </div>
        <div class="alert alert-danger" role="alert" v-if="errorChannelUploadLimit">
          {{ errorChannelUploadLimit}}
        </div>
        <div class="text-center">
          <button type="submit" class="btn btn-dark-purple">Submit</button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import axios from 'axios';
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@fortawesome/fontawesome-free/css/all.css';


export default {
  components: {FontAwesomeIcon, QuillEditor},
  data() {
    return {
      channel: {
        settings: {
          about_me: ''
        }
      },
      users: [],
      resizeObserver: null,
      successChannel: '',
      successChannelSettings: '',
      successChannelChangeOwnerhsip: '',
      successChannelUploadLimit : '',
      contentCategories: [],
      validationChannelOwnershipErrors: [],
      validationChannelErrors: [],
      validationChannelSettingsErrors: [],
      errorChannelUploadLimit : '',
    }
  },
  methods: {
    fetchChannel() {
      axios.get(`admin/channels/${this.$route.params.id}`)
          .then(response => {
            console.log(response.data);
            this.channel = response.data;
          })
          .catch(error => {
            console.log(error);
          });
    },
    fetchContentCategories() {
      axios.get(`admin/content-categories`)
          .then(response => {
            this.contentCategories = response.data;
          })
          .catch(error => {
            console.log(error);
          });
    },
    updateChannel() {
      this.validationChannelErrors = [];
      axios.put(`admin/channels/${this.$route.params.id}`, this.channel)
          .then(response => {
            this.successChannel = response.data.message;
            setTimeout(() => this.successChannel = '', 30000);
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.validationChannelErrors = error.response.data.errors;
            }
          });
    },
    changeChannelOwnership() {
      this.validationChannelOwnershipErrors = [];
      axios.put(`admin/change-ownership/${this.$route.params.id}`, {
        'email': this.channel.email
      })
          .then(response => {
            this.successChannelChangeOwnerhsip = response.data.success;
            setTimeout(() => this.successChannelChangeOwnerhsip = '', 30000);
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.validationChannelOwnershipErrors = error.response.data.errors;
            }
          });
    },
    updateChannelSettings() {
      this.validationChannelSettingsErrors = [];
      axios.put(`admin/channels/settings/${this.$route.params.id}`, this.channel.settings)
          .then(response => {
            this.successChannelSettings = response.data.message;
            setTimeout(() => this.successChannelSettings = '', 30000);
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.validationChannelSettingsErrors = error.response.data.errors;
            }
          });
    },
    updateChannelUploadLimits() {
      axios.put(`admin/channels/upload/limit/${this.$route.params.id}`, { upload_limits : this.channel.upload_limits })
      .then(response => {
        this.successChannelUploadLimit = response.data.message;
      })
      .catch(error => {
        this.errorChannelUploadLimit = error;
      });
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.fetchChannel();
    this.fetchContentCategories();
  }
}
</script>