<template>
  <br/>
  <div class="table-responsive container-fluid">
    <h4 class="text-center">{{userName ?? 'John Doe'}} unlocked post</h4>
    <router-link :to="{name: 'SingleUser', params: {id: this.$route.params.id}}">Back to user</router-link>
    <table class="table">
      <thead>
      <tr>
        <th scope="col"># ID</th>
        <th scope="col">Creator</th>
        <th scope="col">Post</th>
        <th scope="col">Link</th>
        <th scope="col">Unlocked at</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="unlockedPosts.length > 0" v-for="unlockedPost in unlockedPosts">
        <th scope="row">{{unlockedPost.id}}</th>
        <td>{{unlockedPost.channel.channel.name}}</td>
        <td>{{unlockedPost.name}}</td>
        <td><a :href="unlockedPost.link" class="link-primary" target="_blank">{{unlockedPost.link}}</a></td>
        <td>{{unlockedPost.date}}</td>
      </tr>
      <tr v-else-if="loading">
        <td colspan="5" class="text-center"><font-awesome-icon icon="fa-solid fa-sync fa-10x" spin/></td>
      </tr>
      <tr v-else>
        <td colspan="5" class="text-center">No unlocked posts</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      loading: true,
      unlockedPosts: [],
      userName: '',
    }
  },

  mounted() {
    this.getPosts();
    this.getUserName();
  },

  methods: {
    getPosts() {
      this.loading = true;
      axios.get(`/admin/unlocked-posts/user/${this.$route.params.id}`).then(response => {
        this.unlockedPosts = response.data.data;
        this.loading = false;
      })
          .catch(error => {
            this.loading = false;
            console.log(error);
          });
    },
    getUserName() {
      axios.get(`/admin/user-name/${this.$route.params.id}`).then(response => {
        this.userName = response.data.data;
      })
          .catch(error => {
            console.log(error);
          });
    },
  }
}
</script>