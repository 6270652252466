<template>
  <br/>
  <div style="padding:5px"  class="row">
    <h4 class="text-center">{{userName ?? 'Name'}} pledges</h4>
    <div class="col-lg-4 col-md-6 col-sm-12 mb-3">
      <label for="search" class="form-label mt-2 me-2">Keyword</label>
      <input id="search" class="form-control" placeholder="Creator or tier" v-model="keyword" @input="searchKeyword"/>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-12 mb-3">
      <label for="status" class="form-label me-2 mt-2">Status</label>
      <select id="status" class="form-control" v-model="status" @click="changeStatus(status)">
        <option value="" :value="status">All</option>
        <option value="1" :value="status">Active</option>
        <option value="2" :value="status">Charged failed</option>
        <option value="0" :value="status">Cancelled</option>
      </select>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-12 mb-3">
      <label for="per-page" class="form-label me-1 mt-2">Per page</label>
      <select id="per-page" class="form-control" v-model="perPage" @click="perPageFilter(perPage)">
        <option value="10" :value="perPage">10</option>
        <option value="20" :value="perPage">20</option>
        <option value="50" :value="perPage">50</option>
      </select>
    </div>
    <div class="table-responsive container-fluid">
    <router-link :to="{name: 'SingleUser', params: {id: this.$route.params.id}}">Back to user</router-link>
    <div class="alert alert-success" role="alert" v-if="success">
      {{ success}}
    </div>
    <div class="alert alert-danger" role="alert" v-if="errorConfirm">
      {{ errorConfirm}}
    </div>
    <table class="table">
      <thead>
      <tr>
        <th scope="col"># ID</th>
        <th scope="col">Creator</th>
        <th scope="col">Tier</th>
        <th scope="col">Per month</th>
        <th scope="col">Expires at</th>
        <th scope="col">Status</th>
        <th scope="col">Stripe link</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="pledges.length > 0" v-for="pledge in pledges">
        <th scope="row">{{pledge.id}}</th>
        <td>
          <router-link :to="{name: 'SingleChannel', params: {id: pledge.channel_id}}" target="_blank">
            {{pledge.channel_name}}
          </router-link>
        </td>
        <td>{{pledge.tier.name}}</td>
        <td>
          <p>{{pledge.amount}} {{pledge.currency}}</p>
        </td>
        <td>{{pledge.expire}}<div><a style="cursor:pointer;color:blue;text-decoration:underline;" data-bs-toggle="modal" data-bs-target="#subscriptionModal" @click="loadExpirationModal(pledge.id, pledge.expire)">(change)</a></div></td>
        <td>
              <span v-if="pledge.active === 1 && new Date(pledge.expire) > new Date(pledge.today)"
                    class="badge badge-success">
            <font-awesome-icon icon="fas fa-check-circle"/>
            Active
          </span>
          <span v-else-if="pledge.active === 1 && new Date(pledge.expire) < new Date(pledge.today)"
                class="badge badge-danger">
            <font-awesome-icon icon="fas fa-times"/>
            Failed / trying to extend
          </span>
          <span v-else-if="pledge.active === 0" class="badge badge-danger">
            <font-awesome-icon icon="fas fa-times"/>
            Cancelled
          </span>
          <span v-else class="badge badge-secondary">
            Undefined
          </span>
        </td>
        <td>
          <a v-if="pledge.stripe_link" :href="pledge.stripe_link" target="_blank">Click here</a>
          <p v-else>-</p>
          <br/>
          <a v-if="pledge.stripe_link_alternative" :href="pledge.stripe_link_alternative" target="_blank">
            Click here (alternative)
          </a>
          <p v-else>-</p>
        </td>
      </tr>
      <tr v-else-if="loading">
        <td colspan="7" class="text-center"><font-awesome-icon icon="fa-solid fa-sync fa-10x" spin/></td>
      </tr>
      <tr v-else>
        <td colspan="7" class="text-center">No pledges</td>
      </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-between" v-if="totalRecords >= 1">
      <div>
        <div class="p-2">
          <p>Total subscriptions: {{ totalRecords }}</p>
        </div>
      </div>
      <div>
        <div class="p-2">
          <nav v-if="totalPages >= 1" aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item" :class="{disabled: currentPage - 1 < 1}">
                <a @click="paginate(currentPage - 1)" class="page-link">Previous</a>
              </li>
              <li class="page-item" :class="{active: 1 === currentPage}">
                <a @click="paginate(1)" class="page-link" :class="{'not-clickable': currentPage === 1}">1</a>
              </li>
              <li class="page-item" v-if="currentPage - 2 > 1">
                <a href="#" class="page-link">...</a>
              </li>
              <li class="page-item" v-if="currentPage - 2 > 1">
                <a @click="paginate(currentPage - 2)" class="page-link">{{ currentPage - 2}}</a>
              </li>
              <li class="page-item" v-if="currentPage - 1 > 1">
                <a @click="paginate(currentPage - 1)" class="page-link">{{ currentPage - 1}}</a>
              </li>
              <li class="page-item" v-if="currentPage !== 1"  :class="{active: currentPage}">
                <a @click="paginate(currentPage)" class="page-link" :class="{'not-clickable': currentPage}">{{currentPage }}</a>
              </li>
              <li class="page-item" v-if="currentPage + 1 <= totalPages">
                <a @click="paginate(currentPage + 1)" class="page-link">{{ currentPage + 1}}</a>
              </li>
              <li class="page-item" v-if="currentPage + 2 <= totalPages">
                <a @click="paginate(currentPage + 2)" class="page-link">{{ currentPage + 2}}</a>
              </li>
              <li class="page-item" v-if="currentPage + 2 < totalPages">
                <a href="#" class="page-link">...</a>
              </li>
              <li class="page-item" v-if="currentPage !== totalPages && currentPage + 2 < totalPages" :class="{active: totalPages === currentPage}">
                <a @click="paginate(totalPages)" class="page-link" :class="{'not-clickable': totalPages === currentPage}">{{totalPages }}</a>
              </li>
              <li class="page-item" :class="{disabled: currentPage + 1 > totalPages}">
                <a @click="paginate(currentPage + 1)" class="page-link">Next</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
  </div>
  <div class="modal fade" id="subscriptionModal" tabindex="-1" role="dialog" aria-labelledby="subscriptionModalTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content" v-if="!modalLoading">
        <div class="modal-header">
          <h5 class="modal-title" id="subscriptionModalTitle">Subscription #{{pledgeModal.id}}</h5>
        </div>
        <div class="modal-body">
          <div v-if="!modalLoading">
            <form @submit.prevent="updateExpirationDate" novalidate>
              <label for="pledge_expiration_date">Update expiration date:</label>
              <input v-model="pledgeModal.expiration_date" class="form-control" type="datetime-local" id="pledge_expiration_date" name="pledge_expiration_date">
              <div style="margin-top: 15px;">
                <button type="submit" class="btn btn-primary">Submit</button>
              </div>
            </form> 
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
      <div class="modal-content" v-else>
        <div class="modal-body">
          <font-awesome-icon icon="fa-solid fa-sync fa-10x" spin/>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import _debounce from "lodash/debounce";

export default {
  data() {
    return {
      loading: true,
      modalLoading: false,
      pledges: [],
      pledgeModal: {

      },
      userName: '',
      keyword: '',
      status: '',
      success: '',
      errorConfirm: '',
      currentPage: 1,
      totalPages: 0,
      totalRecords: 0,
      perPage: 10
    }
  },

  mounted() {
    this.getPledges('', '', 1, 20);
    this.getUserName();
  },

  methods: {
    debouncedGetPledges: _debounce(function (keyword, status, page, perPage) {
      this.loading = true;
      axios.get(`/admin/pledges/user/${this.$route.params.id}?page=${page}&per_page=${perPage}&keyword=${keyword}&status=${status}`).then(response => {
        this.pledges = response.data.data;
        this.totalPages = response.data.totalPages;
        this.totalRecords = response.data.totalRecords;
        this.loading = false;
      })
          .catch(error => {
            this.loading = false;
            console.log(error);
          });
    }, 500),
    getPledges(keyword, status, page, perPage) {
      this.debouncedGetPledges(keyword, status, page, perPage);
    },
    getUserName() {
      axios.get(`/admin/user-name/${this.$route.params.id}`).then(response => {
          this.userName = response.data.data;
        })
          .catch(error => {
            console.log(error);
          });
    },
    changeStatus(status) {
      this.status = status;
      this.getPledges(this.keyword, this.status, this.currentPage, this.perPage);
    },
    searchKeyword() {
      this.getPledges(this.keyword, this.status, this.currentPage, this.perPage);
    },
    perPageFilter(perPage) {
      this.perPage = perPage;
      this.getPledges(this.keyword, this.status, this.currentPage, this.perPage);
    },
    paginate(page) {
      this.currentPage = page;
      this.getPledges(this.keyword, this.status, this.currentPage, this.perPage);
    },
    loadExpirationModal(id, expireDate) {
      this.pledgeModal.id = id;
      this.pledgeModal.expiration_date = expireDate;
    },
    updateExpirationDate() {
      this.modalLoading = true;
      axios.put(`admin/pledges/update/expire/${this.pledgeModal.id}`, {expireDate : this.pledgeModal.expiration_date})
      .then(response => {
        this.success = response.data.message;
        this.getPledges(this.keyword, this.status, this.currentPage, this.perPage);
      })
      .catch(error => {
        if(error.response.status == 422) {
          this.errorConfirm = error.response.data.message;
        }
        else {
          this.errorConfirm = error;
        }
      })
      .finally(response => {
        this.modalLoading = false;
      });
    }
  }
}
</script>
