<template>
  <br/>
  <div class="table-responsive container-fluid">
    <router-link :to="{name: 'AddUserManagement'}">Add user-admin role</router-link>
    <div class="alert alert-success" role="alert" v-if="success" >
      {{ success}}
    </div>
    <table class="table">
      <thead>
      <tr>
        <th scope="col"># ID</th>
        <th scope="col">Name</th>
        <th scope="col">Email</th>
        <th scope="col">Role</th>
        <th scope="col">Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="users.length > 0" v-for="user in users">
        <th scope="row">{{user.id}}</th>
        <td>{{user.name}}</td>
        <td>{{user.email}}</td>
        <td>
          <select class="form-control" v-model="user.role[0].id">
            <option v-for="role in roles" :value="role.id">{{role.name}}</option>
          </select>
        </td>
        <td>
          <div class="btn-group-sm d-flex gap-3" role="group">
            <button @click="updateRole(user.id, user.email, user.role[0].id)" class="btn btn-success">
              Save
            </button>
            <button @click="deleteRole(user.id)" class="btn btn-danger">
              Delete
            </button>
          </div>
        </td>
      </tr>
      <tr v-else-if="loading">
        <td colspan="5" class="text-center"><font-awesome-icon icon="fa-solid fa-sync fa-10x" spin/></td>
      </tr>
      <tr v-else>
        <td colspan="5" class="text-center">No admin users</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      loading: true,
      users: [],
      roles: [],
      success: ''
    }
  },

  mounted() {
    this.getUserRoles();
    this.getRoles();
  },

  methods: {
    getUserRoles() {
      this.loading = true;
      axios.get(`/admin/user-management`).then(response => {
        this.users = response.data.data;
        this.loading = false;
      })
          .catch(error => {
            this.loading = false;
            console.log(error);
          });
    },
    getRoles() {
      axios.get(`/admin/roles`).then(response => {
        this.roles = response.data.data;
      })
          .catch(error => {
            console.log(error);
          });
    },
    updateRole(userId, email, role) {
      axios.put(`admin/user-management/${userId}`, {
        'email': email,
        'role': role
      })
          .then(response => {
             this.success = response.data.message;
             setTimeout(() => this.success = '', 60000)
          })
          .catch(error => {
            console.log(error);
          })
    },
    deleteRole(userId) {
      axios.delete(`admin/user-management/${userId}`)
          .then(response => {
            this.success = response.data.message;
            setTimeout(() => this.success = '', 60000)
            this.getUserRoles();
          })
          .catch(error => {
            console.log(error);
          })
    }
  }
}
</script>