<template>
  <br/>
  <div class="row">
    <div class="table-responsive container-fluid">
      <table class="table">
        <thead>
        <tr>
          <th scope="col"># ID</th>
          <th scope="col">Date</th>
          <th scope="col">Seller</th>
          <th scope="col">Product name</th>
          <th scope="col">Price</th>
          <th scope="col">Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="orders.length > 0" v-for="order in orders">
          <th scope="row">{{ order.id }}</th>
          <td>{{ order.time }}</td>
          <td>{{ order.channel.name }}</td>
          <td>{{ order.products[0].title }}</td>
          <td>
            <p>{{ order.cost / 100 }} {{ order.currency }}</p>
          </td>
          <td>
            <p>
              <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#orderModal" @click="fetchDeatiledOrder(order.id)">
                View more
              </button>
            </p>
          </td>
        </tr>
        <tr v-else-if="loading">
          <td colspan="8" class="text-center"><font-awesome-icon icon="fa-solid fa-sync fa-10x" spin/></td>
        </tr>
        <tr v-else>
          <td colspan="8" class="text-center">No orders</td>
        </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-between" v-if="totalRecords >= 1">
        <div>
          <div class="p-2">
            <p>Total orders: {{ totalRecords }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="orderModal" tabindex="-1" role="dialog" aria-labelledby="orderModalTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content" v-if="!modalLoading">
        <div class="modal-header">
          <h5 class="modal-title" id="orderModalTitle">Order #{{detailedOrder.id}}</h5>
        </div>
        <div class="modal-body">
          <div v-if="detailedOrder.ordered_products">
            <b>Buyer</b>
            <div>Name - {{detailedOrder.ordered_products[0].client_name}}</div>
            <div>Email - {{detailedOrder.ordered_products[0].client_email}}</div>
            <div>Phone number - {{detailedOrder.ordered_products[0].client_phone_number}}</div>
            <hr/>
            <b>Shipping information</b>
            <div>Zip code - {{detailedOrder.ordered_products[0].zip_code}}</div>
            <div>City - {{detailedOrder.ordered_products[0].city}}</div>
            <div>Country - {{detailedOrder.ordered_products[0].country}}</div>
            <hr/>
            <div v-if="detailedOrder.ordered_products[0].product" class="row">
              <div class="col">
                <img class="img-thumbnail" v-bind:src="detailedOrder.ordered_products[0].product.media">
              </div>
              <div class="col">
                <b>{{detailedOrder.ordered_products[0].product.title}}</b>
                <div v-for="attribute in detailedOrder.ordered_products[0].attributes">
                  <div>{{attribute.name}} : {{attribute.option}}</div>
                </div>
                <div style="position:absolute;bottom:0;">Quantity: {{detailedOrder.ordered_products[0].quantity}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
      <div class="modal-content" v-else>
        <div class="modal-body">
          <font-awesome-icon icon="fa-solid fa-sync fa-10x" spin/>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  components: {FontAwesomeIcon},
  data() {
    return {
      loading: true,
      modalLoading: false,
      currentPage: 1,
      totalPages: 0,
      totalRecords: 0,
      perPage: 20,
      orders: [],
      detailedOrder: [],
    }
  },

  mounted() {
    this.fetchOrders();
  },

  methods: {

    fetchOrders() {
      axios.get(`/admin/orders/user/${this.$route.params.id}`)
      .then(response => {
        this.orders = response.data;
        this.totalRecords = response.data.length;
      })
      .catch(error => {
        console.log(error);
      })          
      .finally(response => {
        this.loading = false;
      });
    },

    fetchDeatiledOrder(id) {
      this.modalLoading = true;
      axios.get(`/admin/orders/detailed/user/${this.$route.params.id}/${id}`)
      .then(response => {
        this.detailedOrder = response.data
      })
      .catch(error => {
        console.log(error);
      })          
      .finally(response => {
        this.modalLoading = false;
      });
    }

  }
}
</script>
