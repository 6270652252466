

<template>
  <h1>Unauthorized</h1>
  <router-link v-if="role === 'Ukrainian Manager'" :to="{name: 'UkrainianHome'}">Redirect</router-link>
  <router-link v-else-if="role === 'Admin' || role === 'Super Admin'" :to="{name: 'Home'}">Redirect</router-link>
  <a v-else @click="logout">Log out</a>
</template>

<style scoped>

</style>

<script>
import store from "../../store";

export default {
  data() {
    return {
      role: store.state.auth.user.role
    }
  },
  methods: {
    logout: function () {
      this.$store.dispatch('logout');
      this.$router.push({name: 'login'})
    }
  },
}
</script>