<template>
    <div v-if="loading" class="text-center" style="font-size: 24px">
      <font-awesome-icon icon="fa-solid fa-sync fa-10x" spin/>
    </div>
    <div v-else>
    <div class="user-cover container"
        :style="{backgroundImage: 'url('+ channel.background_picture + ')' }">
    </div>

    <div class="user-image">
        <img id="user-profile-picture"
            :src="channel.profile_picture"
            alt="www.contribee.com">
    </div>

    <div class="user-information mt-4">
        <h1 class="krona-font user-name">{{channel.name}}</h1>
        <p class="word-break">{{channel.creating}}</p>
        <div class="user-statistics">
          <div>
            <h1 class="krona-font">
              <span id="subscribers-box">
                {{contribees}}
              </span>
            </h1>
            <span>Contribees</span>
          </div>
          <div>
            <h1 class="krona-font"><span id="mrr-box">{{mrr}}</span></h1>
            <span>Per month</span>
          </div>
        </div>
      </div>
      <br/>
  <div class="col-lg-6 container-fluid justify-content-center">
    <div class="alert alert-success" role="alert" v-if="success">
      {{ success}}
    </div>
    <div class="alert alert-danger" role="alert" v-if="errorConfirm">
      {{ errorConfirm}}
    </div>
    <div class="btn-group-sm justify-content-center d-flex gap-3" role="group">
      <button v-if="channel.status === 'pending_check' || channel.status === 'denied'" type="button" class="btn btn-success" :disabled="disableConfirm"
              @click="confirmChannel(channel.id)">
        <span v-if="!disableConfirm">Confirm</span>
        <span v-else><font-awesome-icon icon="fa-solid fa-sync" spin/></span>
      </button>
      <button v-if="channel.status === 'pending_check'" type="button" class="btn btn-danger" :disabled="disableDeny"
        @click="denyChannel(channel.id)">
        <span v-if="!disableDeny">Deny</span>
        <span v-else><font-awesome-icon icon="fa-solid fa-sync" spin/></span>
      </button>
      <button v-if="!channel.frozen" type="button" class="btn btn-danger" data-bs-toggle="modal"
              data-bs-target="#restrictModal" :disabled="disableRestrict">
        <span v-if="!disableRestrict">Restrict</span>
        <span v-else><font-awesome-icon icon="fa-solid fa-sync" spin/></span>
      </button>
      <button v-if="channel.frozen" type="button" class="btn btn-danger"  :disabled="disableUnrestrict"
            @click="unrestrictChannel(channel.id)">
        <span v-if="!disableUnrestrict">Un-restrict</span>
        <span v-else><font-awesome-icon icon="fa-solid fa-sync" spin/></span>
      </button>
    </div>
    <br/>
    <div class="accordion" id="channelView">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingGeneralInfo">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#generalInfo" aria-expanded="true" aria-controls="generalInfo">
            General info
          </button>
        </h2>
        <div id="generalInfo" class="accordion-collapse collapse" aria-labelledby="headingGeneralInfo" data-bs-parent="#channelView">
          <div class="accordion-body">
            <table class="table table-bordered table-responsive table-white">
              <tr>
                <th>Name</th>
                <td class="text-right">{{channel.name}}</td>
              </tr>
              <tr>
                <th>Creator Name</th>
                <td class="text-right">{{channel.creator_name}}</td>
              </tr>
              <tr>
                <th>Creator email</th>
                <td class="text-right">{{channel.email}}</td>
              </tr>
              <tr>
                <th>Category</th>
                <td v-if="channel.content_category" class="text-right">
                  {{channel.content_category}}
                </td>
                <td v-else class="text-right">No category defined</td>
              </tr>
              <tr>
                <th>Link</th>
                <td class="text-right"><a class="link-primary" :href="channel.link">{{channel.link}}</a></td>
              </tr>
              <tr>
                <th>Country</th>
                <td class="text-right">{{channel.country}}</td>
              </tr>
              <tr>
                <th>Currency</th>
                <td class="text-right">{{channel.currency}}</td>
              </tr>
              <tr>
                <th>Has payments?</th>
                <td v-if="channel.has_one_payment" class="text-right"><font-awesome-icon :icon="['fas', 'check']" /></td>
                <td v-else class="text-right"><font-awesome-icon :icon="['fas', 'x']" /></td>
              </tr>
              <tr>
                <th>Registered at</th>
                <td class="text-right">{{channel.created_at}}</td>
              </tr>
              <tr>
                <th>Updated at</th>
                <td class="text-right">{{channel.updated_at}}</td>
              </tr>
              <tr v-if="channel.stripe_account">
                <th>Stripe channel</th>
                <td class="text-right">
                  <a :href="channel.stripe_account" target="_blank" class="link-primary">
                    {{channel.stripe_account}}
                  </a>
                </td>
              </tr>
              <tr v-if="channel.fondy_account_id">
                <th>Fondy channel account</th>
                <td class="text-right">
                  {{channel.fondy_account_id}}
                </td>
              </tr>
              <tr v-if="channel.fondy_ma_id">
                <th>Fondy MA id</th>
                <td class="text-right">
                  <a :href="channel.fondy_ma_id" target="_blank" class="link-primary">
                    {{channel.fondy_ma_id}}
                  </a>
                </td>
              </tr>
              <tr>
                <th>Status</th>
                <td>
        <span v-if="channel.status === 'awaiting_confirmation' && channel.country !== 'UA'" class="badge badge-info">
            <font-awesome-icon icon="fa-solid fa-rotate" />
            Awaiting from Stripe
          </span>
                  <span v-else-if="channel.status === 'awaiting_confirmation' && channel.country === 'UA'" class="badge badge-info">
            <font-awesome-icon icon="fa-solid fa-rotate" />
            Awaiting from Fondy
          </span>
                  <span v-else-if="channel.status === 'OK'" class="badge badge-success">
            <font-awesome-icon icon="fas fa-check-circle"/>
            Activated
          </span>
                  <span v-else-if="channel.status === 'pending_check'" class="badge badge-info">
            <font-awesome-icon icon="fa-solid fa-rotate"/>
            Pending from Admin
          </span>
                  <span v-else-if="channel.status === 'denied'" class="badge badge-danger">
            <font-awesome-icon icon="fa-solid fa-circle-xmark"/>
            Denied
          </span>
                  <span v-else class="badge badge-secondary">
            Undefined
          </span>
                </td>
              </tr>
              <tr>
                <th>Adult</th>
                <td v-if="channel.adult || channel.adult === 'yes'" class="text-right"><font-awesome-icon :icon="['fas', 'check']" /></td>
                <td v-else class="text-right"><font-awesome-icon :icon="['fas', 'x']" /></td>
              </tr>
              <tr>
                <th>Featured</th>
                <td v-if="channel.featured" class="text-right"><font-awesome-icon :icon="['fas', 'check']" /></td>
                <td v-else class="text-right"><font-awesome-icon :icon="['fas', 'x']" /></td>
              </tr>
              <tr>
                <th>Searchable</th>
                <td v-if="channel.searchable" class="text-right"><font-awesome-icon :icon="['fas', 'check']" /></td>
                <td v-else class="text-right"><font-awesome-icon :icon="['fas', 'x']" /></td>
              </tr>
              <tr>
                <th>Frozen</th>
                <td v-if="channel.frozen" class="text-right"><font-awesome-icon :icon="['fas', 'check']" /></td>
                <td v-else class="text-right"><font-awesome-icon :icon="['fas', 'x']" /></td>
              </tr>
              <tr>
                <th>C listed</th>
                <td v-if="channel.c_listed" class="text-right"><font-awesome-icon :icon="['fas', 'check']" /></td>
                <td v-else class="text-right"><font-awesome-icon :icon="['fas', 'x']" /></td>
              </tr>
              <tr>
                <th>Payment provider</th>
                <td class="text-right">{{channel.provider}}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="socialNetworkHeading">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#socialNetwork" aria-expanded="false" aria-controls="socialNetwork">
            Channel social network URLs
          </button>
        </h2>
        <div id="socialNetwork" class="accordion-collapse collapse" aria-labelledby="socialNetworkHeading" data-bs-parent="#channelView">
          <div class="accordion-body">
            <table class="table table-bordered table-responsive table-white">
              <tr>
                <th>Instagram</th>
                <td v-if="channel.settings.instagram_url" class="text-right">
                  <a class="link-primary" :href="channel.settings.instagram_url">
                    {{channel.settings.instagram_url}}
                  </a>
                </td>
                <td v-else>-</td>
              </tr>
              <tr>
                <th>Facebook</th>
                <td v-if="channel.settings.facebook_url" class="text-right">
                  <a class="link-primary" :href="channel.settings.facebook_url">
                    {{channel.settings.facebook_url}}
                  </a>
                </td>
                <td v-else>-</td>
              </tr>
              <tr>
                <th>LinkedIn</th>
                <td v-if="channel.settings.linkedin_url" class="text-right">
                  <a class="link-primary" :href="channel.settings.linkedin_url">
                    {{channel.settings.linkedin_url}}
                  </a>
                </td>
                <td v-else>-</td>
              </tr>
              <tr>
                <th>X (former Twitter)</th>
                <td v-if="channel.settings.twitter_url" class="text-right">
                  <a class="link-primary" :href="channel.settings.twitter_url">
                    {{channel.settings.twitter_url}}
                  </a>
                </td>
                <td v-else>-</td>
              </tr>
              <tr>
                <th>Twitch</th>
                <td v-if="channel.settings.twitch_url"  class="text-right">
                  <a class="link-primary" :href="channel.settings.twitch_url">
                    {{channel.settings.twitch_url}}
                  </a>
                </td>
                <td v-else>-</td>
              </tr>
              <tr>
                <th>GitHub</th>
                <td v-if="channel.settings.github_url" class="text-right">
                  <a class="link-primary" :href="channel.settings.github_url">
                    {{channel.settings.github_url}}
                  </a>
                </td>
                <td v-else>-</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingChannelSettings">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#channelSettings" aria-expanded="false" aria-controls="channelSettings">
            Other channel settings
          </button>
        </h2>
        <div id="channelSettings" class="accordion-collapse collapse" aria-labelledby="headingChannelSettings" data-bs-parent="#channelView">
          <div class="accordion-body">
            <table class="table table-bordered table-responsive table-white">
              <tr>
                <th>Public earnings</th>
                <td v-if="channel.settings.public_earnings" class="text-right"><font-awesome-icon :icon="['fas', 'check']" /></td>
                <td v-else class="text-right"><font-awesome-icon :icon="['fas', 'x']" /></td>
              </tr>
              <tr>
                <th>Public subscribers</th>
                <td v-if="channel.settings.public_subscribers" class="text-right"><font-awesome-icon :icon="['fas', 'check']" /></td>
                <td v-else class="text-right"><font-awesome-icon :icon="['fas', 'x']" /></td>
              </tr>
              <tr>
                <th>Public onetime</th>
                <td v-if="channel.settings.public_onetime" class="text-right"><font-awesome-icon :icon="['fas', 'check']" /></td>
                <td v-else class="text-right"><font-awesome-icon :icon="['fas', 'x']" /></td>
              </tr>
              <tr>
                <th>About me</th>
                <td class="text-right" v-html="channel.settings.about_me"></td>
              </tr>
              <tr>
                <th>One time description</th>
                <td class="text-right" v-html="channel.settings.onetime_description"></td>
              </tr>
              <tr>
                <th>Auth one time</th>
                <td v-if="channel.settings.auth_onetime" class="text-right"><font-awesome-icon :icon="['fas', 'check']" /></td>
                <td v-else class="text-right"><font-awesome-icon :icon="['fas', 'x']" /></td>
              </tr>
              <tr>
                <th>Google analytics ID</th>
                <td class="text-right">{{channel.settings.google_analytics_id}}</td>
              </tr>
              <tr>
                <th>Plyr</th>
                <td v-if="channel.settings.plyr" class="text-right"><font-awesome-icon :icon="['fas', 'check']" /></td>
                <td v-else class="text-right"><font-awesome-icon :icon="['fas', 'x']" /></td>
              </tr>
              <tr>
                <th>OneTimer window first</th>
                <td v-if="channel.settings.onetime_window_first" class="text-right"><font-awesome-icon :icon="['fas', 'check']" /></td>
                <td v-else class="text-right"><font-awesome-icon :icon="['fas', 'x']" /></td>
              </tr>
              <tr>
                <th>Enable OneTimer buttons</th>
                <td v-if="channel.settings.enable_onetimer_first" class="text-right"><font-awesome-icon :icon="['fas', 'check']" /></td>
                <td v-else class="text-right"><font-awesome-icon :icon="['fas', 'x']" /></td>
              </tr>
              <tr>
                <th>First tip price</th>
                <td class="text-right">{{channel.settings.first_tip_price}}</td>
              </tr>
              <tr>
                <th>Second tip price</th>
                <td class="text-right">{{channel.settings.second_tip_price}}</td>
              </tr>
              <tr>
                <th>Third tip price</th>
                <td class="text-right">{{channel.settings.third_tip_price}}</td>
              </tr>
              <tr>
                <th>Top spender</th>
                <td v-if="channel.settings.top_sender" class="text-right"><font-awesome-icon :icon="['fas', 'check']" /></td>
                <td v-else class="text-right"><font-awesome-icon :icon="['fas', 'x']" /></td>
              </tr>
              <tr>
                <th>RSS</th>
                <td v-if="channel.settings.rss_on" class="text-right"><font-awesome-icon :icon="['fas', 'check']" /></td>
                <td v-else class="text-right"><font-awesome-icon :icon="['fas', 'x']" /></td>
              </tr>
              <tr>
                <th>Copy text</th>
                <td v-if="channel.settings.copy_text" class="text-right"><font-awesome-icon :icon="['fas', 'check']" /></td>
                <td v-else class="text-right"><font-awesome-icon :icon="['fas', 'x']" /></td>
              </tr>
              <tr>
                <th>Chat with non subscribers</th>
                <td v-if="channel.settings.chat_with_non_subscribers" class="text-right"><font-awesome-icon :icon="['fas', 'check']" /></td>
                <td v-else class="text-right"><font-awesome-icon :icon="['fas', 'x']" /></td>
              </tr>
              <tr>
                <th>Post OneTimer Button</th>
                <td v-if="channel.settings.post_onetimer_button" class="text-right"><font-awesome-icon :icon="['fas', 'check']" /></td>
                <td v-else class="text-right"><font-awesome-icon :icon="['fas', 'x']" /></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="accordion-item" v-if="loginUserRole !== 'Ukrainian Manager'">
        <h2 class="accordion-header" id="userDetailedInformationHeader">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#userDetailedInformatio" aria-expanded="false" aria-controls="userDetailedInformatio">
            Detailed information
          </button>
        </h2>
        <div id="userDetailedInformatio" class="accordion-collapse collapse" aria-labelledby="userDetailedInformationHeader" data-bs-parent="#userView">
          <div class="accordion-body">
            <table style="border-radius: 15px;" class="table table-bordered table-white table-responsive rounded-lg">
              <tr>
                <th>Active subscriptions count</th>
                <td style="word-break: break-all;" class="text-right"> {{channelDetailed.active_pledges_count}} </td>
              </tr>
              <tr>
                <th>Active PPC count</th>
                <td style="word-break: break-all;" class="text-right"> {{channelDetailed.active_ppc_pledges_count}} </td>
              </tr>
              <tr>
                <th>Active Fondy subscriptions count</th>
                <td class="text-right"> {{channelDetailed.active_fondy_pledges}} </td>
              </tr>
              <tr>
                <th>Payouts count</th>
                <td class="text-right"> {{channelDetailed.payouts_count}} </td>
              </tr>
              <tr>
                <th>Pending payouts count</th>
                <td class="text-right"> {{channelDetailed.payouts_pending_count}} </td>
              </tr>
              <tr>
                <th>Stripe available amount</th>
                <td class="text-right"> {{channelDetailed.stripe_available_amount / 100}} {{ channelDetailed.currency }} </td>
              </tr>
              <tr>
                <th>Stripe pending amount</th>
                <td class="text-right"> {{channelDetailed.stripe_pending_amount / 100}} {{ channelDetailed.currency }} </td>
              </tr>
              <tr>
                <th>Delete creator</th>
                <td class="text-right">
                  <button @click="deleteChannel(channel.name, channel.id)" class="btn btn-danger text-white">
                    Click here to delete
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="userLinksHeading">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#creatorLinks" aria-expanded="false" aria-controls="creatorLinks">
            Creator links
          </button>
        </h2>
        <div id="creatorLinks" class="accordion-collapse collapse" aria-labelledby="creatorLinksHeading" data-bs-parent="#userView">
          <div class="accordion-body">
            <table style="border-radius: 15px;" class="table table-bordered table-white table-responsive rounded-lg">
              <tr>
                <th colspan="2" class="text-center">
                  <router-link style="text-decoration: underline;" :to="{name: 'ChannelPosts', params: {id: this.$route.params.id}}">Creator posts
                  </router-link>
                </th>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
    <div class="container col-lg-6">
        <div class="row mt-5">
          <div class="button-group" role="group">
            <a @click="filterDate('today')" type="button" class="btn btn-dark-purple mb-3 me-2">Today</a>
            <a @click="filterDate('thisMonth')" type="button" class="btn btn-dark-purple mb-3 me-2">This month</a>
            <a @click="filterDate('thisYear')" type="button" class="btn btn-dark-purple mb-3 me-2">This year</a>
            <a @click="filterDate('total')" type="button" class="btn btn-dark-purple mb-3 me-2">Total</a>
            <button type="button" class="btn btn-dark-purple mb-3 me-2" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
              Custom
            </button>
          </div>
          <span v-if="statsLoading"><font-awesome-icon icon="fa-solid fa-sync fa-10x" spin/></span>
          <p v-else>Date filter {{fromDate}} - {{toDate}}</p>
          <br/>
          <div v-for="(card, index) in cards" :key="index" class="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
            <div class="card">
              <div class="card-body">
                <h6 class="card-title">{{ card.title }}</h6>
                <p class="card-text">
                  {{ card.value }} {{selectedCurrencySymbol}}
                </p>
              </div>
            </div>
          </div>
        </div>
    </div>
    </div>
  <!-- Modal -->
  <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="staticBackdropLabel">Custom creator date filter form</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label for="fromDate" class="form-label">From date</label>
            <input type="date" class="form-control me-1" id="fromDate" v-model="fromDate" @input="formatCustomDate('fromDate')">
          </div>
          <div class="mb-3">
            <label for="toDate" class="form-label">To date</label>
            <input type="date" class="form-control me-1" id="toDate" v-model="toDate" @input="formatCustomDate('toDate')">
          </div>
        </div>
        <div class="modal-footer">
          <button @click="fetchChannelStats(fromDate, toDate)" type="button" class="btn btn-dark-purple me-2">Search</button>
          <a @click="clearFilter" type="button" class="btn btn-dark-purple">Clear</a>
        </div>
      </div>
    </div>
  </div>
  <div v-if="!channel.frozen" class="modal fade" id="restrictModal" tabindex="-1" aria-labelledby="restrictModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="restrictModalLabel">Restrict creator {{channel.name}} form</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label for="reason" class="form-label me-2 mt-2">Reason</label>
            <textarea cols="8" rows="8" id="reason" class="form-control" placeholder="Add reason here..." v-model="reason">
                      </textarea>
            <p v-if="error" v-for="reasons in error.reason" class="text-danger">{{ reasons}}</p>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" @click="restrictChannel(channel.id)">Submit restriction</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios';
import Swal from "sweetalert2";
import store from "@/store";

export default {
  data() {
    return {
      channel: {
        settings: {},
      },
      channelDetailed: {},
      loading: true,
      statsLoading: true,
      contribees: 0,
      disableConfirm: false,
      disableDeny: false,
      disableRestrict: false,
      disableUnrestrict: false,
      error: '',
      errorConfirm: '',
      mrr: 0,
      fromDate: '',
      toDate: '',
      reason: '',
      success: '',
      selectedCurrency: 'EUR',
      selectedCurrencySymbol: '€',
      loginUserRole: store.state.auth.user.role,
      cards: [
        {
          title: 'Total revenue',
          value: 0, //this is full value
        },
        {
          title: 'Total earnings',
          value: 0,
        },
        {
          title: 'One time tips',
          value: 0,
        },
        {
          title: 'Pay per post',
          value: 0, //this is full value
        },
        {
          title: 'Total pay per post',
          value: 0,
        },
        {
          title: 'Fundraiser',
          value: 0,
        },
        {
          title: 'Merchant',
          value: 0,
        },
        {
          title: 'Pay per category',
          value: 0,
        },
        {
          title: 'Total pay per category',
          value: 0,
        },
        {
          title: 'Subscription sum',
          value: 0,
        },
      ],
    }
  },

  methods: {
    filterDate(filterParam) {
      const date = new Date();
      if (filterParam === 'today') {
        this.fromDate = new Date();
        this.toDate = new Date();
        this.fromDate = this.fromDate.toISOString().split('T')[0];
        this.toDate = this.toDate.toISOString().split('T')[0];
      }
      else if (filterParam === 'thisYear') {
        this.fromDate = new Date(date.getFullYear(), 0, 1);
        this.toDate = new Date(date.getFullYear(), 11, 31);
        this.fromDate = this.formatDate(this.fromDate);
        this.toDate = this.formatDate(this.toDate);
      }
      else if (filterParam === 'thisMonth') {
        this.fromDate = new Date(date.getFullYear(), date.getMonth(), 1);
        this.toDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.fromDate = this.formatDate(this.fromDate);
        this.toDate = this.formatDate(this.toDate);
      }
      else if (filterParam === 'total') {
        this.fromDate = new Date(2018, 0, 1);
        this.fromDate = this.formatDate(this.fromDate);
        this.toDate = this.formatDate(date);
      }
      this.fetchChannelStats(this.fromDate, this.toDate);
    },
    clearFilter() {
      this.selectedCurrency = 'EUR';
      this.selectedCurrencySymbol = '€';
      this.filterDate('thisMonth');
      this.fetchChannelStats(this.fromDate, this.toDate);
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    formatCustomDate(date) {
      let dateValue = this[date];

      // Check if the input is a valid date
      if (dateValue) {
        // Convert the input to a Date object
        const dateObject = new Date(dateValue);
        this[date] = this.formatDate(dateObject);
      }
    },
    fetchChannel() {
      this.loading = true;
      axios.get(`admin/channels/${this.$route.params.id}`)
          .then(response => {
            this.channel = response.data;
            this.loading = false;
          })
          .catch(error => {
            console.log(error);
            this.loading = false;
          });
    },
    fetchChannelStats(fromDate, toDate) {
      this.statsLoading = true
      if (fromDate > toDate) {
        [this.toDate, this.fromDate] = [this.fromDate, this.toDate];
        [toDate, fromDate] = [fromDate, toDate];
      }
      axios.get(`admin/dashboard/channel/${this.$route.params.id}?from_date=${fromDate}&to_date=${toDate}`)
          .then(response => {
            this.cards[0].value = response.data.data.total_revenue;
            this.cards[1].value = response.data.data.total_earnings;
            this.cards[2].value = response.data.data.one_time_tips_sum;
            this.cards[3].value = response.data.data.pay_per_post_sum;
            this.cards[4].value = response.data.data.pay_per_post_count;
            this.cards[5].value = response.data.data.fundraiser_sum;
            this.cards[6].value = response.data.data.merchant_sum;
            this.cards[7].value = response.data.data.pay_per_category_sum;
            this.cards[8].value = response.data.data.pay_per_category_count;
            this.cards[9].value = response.data.data.subscriptions_total_sum;
            this.contribees = response.data.data.active_pledges_count;
            this.mrr = response.data.data.mrr;
            this.selectedCurrency = response.data.currency;
            this.selectedCurrencySymbol = response.data.currency_symbol;
            this.statsLoading = false;
          })
          .catch(error => {
            this.statsLoading = false;
            console.log(error);
          });
    },
    fetchChannelDetailed() {
      this.loading = true;
      axios.get(`admin/detailed/channel?id=${this.$route.params.id}`)
        .then(response => {
          this.channelDetailed = response.data;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    async confirmChannel(channel) {
      const confirmed = await Swal.fire({
        title: 'Confirmation',
        text: 'Are you sure you want to perform this operation?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, proceed',
        cancelButtonText: 'Cancel'
      });
      if (confirmed.isConfirmed) {
        this.success = '';
        this.error = '';
        this.reason = '';
        this.disableConfirm = true;
        await axios.put(`admin/channel/confirm/${channel}`)
            .then(response => {
              this.success = response.data.message;
              this.channel.status = 'OK';
              this.disableConfirm = false;
              setTimeout(() => this.success = '', 60000);
            })
            .catch(error => {
              console.log(error);
              if (error.response.status === 422) {
                this.errorConfirm = error.response.data.message;
                this.disableConfirm = false;
                setTimeout(() => this.error = '', 60000);
              }
            })
      }
    },
    async denyChannel(channel) {
      const confirmed = await Swal.fire({
        title: 'Confirmation',
        text: 'Are you sure you want to perform this operation?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, proceed',
        cancelButtonText: 'Cancel'
      });
      if (confirmed.isConfirmed) {
        this.success = '';
        this.disableDeny = true;
        axios.put(`admin/channel/deny/${channel}`)
            .then(response => {
              this.success = response.data.message;
              this.fetchChannel();
              this.disableDeny = false;
              setTimeout(() => this.success = '', 60000);
            })
            .catch(error => {
              console.log(error);
              this.disableDeny = false;
            })
      }
    },
    restrictChannel(channel) {
      this.success = '';
      this.error = '';
      this.disableRestrict = true;
      axios.put(`admin/channel/restrict/${channel}`,{'reason': this.reason})
          .then(response => {
            this.success = response.data.message;
            this.closeModal('restrictModal');
            this.fetchChannel();
            this.disableRestrict = false;
            this.reason = '';
            setTimeout(() => this.success = '', 60000);
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.error = error.response.data.errors;
              this.disableRestrict = false;
              setTimeout(() => this.error = '', 60000);
            }
          })
    },
    async unrestrictChannel(channel) {
      const confirmed = await Swal.fire({
        title: 'Confirmation',
        text: 'Are you sure you want to perform this operation?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, proceed',
        cancelButtonText: 'Cancel'
      });
      if (confirmed.isConfirmed) {
        this.success = '';
        this.error = '';
        this.reason = '';
        this.disableUnrestrict = true;
        await axios.put(`admin/channel/unrestrict/${channel}`)
            .then(response => {
              this.success = response.data.message;
              this.channel.frozen = false;
              this.disableUnrestrict = false;
              setTimeout(() => this.success = '', 60000);
            })
            .catch(error => {
              if (error.response.status === 422) {
                this.errorConfirm = error.response.data.message;
                this.disableUnrestrict = false;
                setTimeout(() => this.error = '', 60000);
              }
            })
      }
    },
    async deleteChannel(name, id) {
      const confirmed = await Swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to delete this channel ${name}?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, proceed',
        cancelButtonText: 'Cancel'
      });
      if (confirmed.isConfirmed) {
        this.loading = true;
        await axios.delete(`admin/delete/channel/info?id=${id}`)
          .then(response => {
            if(response.data.errors_occurred.length !== 0) {
              this.errorConfirm = response.data.errors_occurred;
            }
            this.success = response.data.message;
            this.$router.push({ name: 'Channels', query: { channel_deleted: 1} });
          })
          .catch(error => {
            console.log(error);
            this.errorConfirm = error.response.data.message;
          })
          .finally(response => {
            this.loading = false;
          })
      }
    },
    closeModal(modalId) {
      const modal = document.getElementById(modalId);
      // Remove the 'show' class from the modal
      modal.classList.remove('show');

      // Remove the modal-open class from the body
      document.body.classList.remove('modal-open');

      // Clear any backdrop that may have been added
      const backdrop = document.querySelector('.modal-backdrop');
      if (backdrop) {
        backdrop.remove();
      }
    }
  },

  mounted() {
    window.scrollTo(0, 0);
    this.fetchChannel();
    this.fetchChannelDetailed();
    this.filterDate('thisMonth');
  }
}

</script>
