<template>
  <br/>
  <div style="padding:5px"  class="row">
    <div class="col-lg-4 col-md-6 col-sm-12 mb-3">
      <label for="search" class="form-label mt-2 me-2">Keyword</label>
      <input type="text" id="search" class="form-control" placeholder="Name or creator" v-model="keyword"
             @input="searchKeyword"/>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-12 mb-3">
      <label for="per-page" class="form-label me-1 mt-2">Type</label>
      <select id="per-page" class="form-control" v-model="type" @click="changeType(type)">
        <option value="" :value="type">All</option>
        <option value="1" :value="type">One time-tips</option>
        <option value="2" :value="type">Subscriptions</option>
        <option value="3" :value="type">Pay per-post</option>
        <option value="4" :value="type">Fundraiser</option>
        <option value="5" :value="type">Merchant e-shop</option>
        <option value="6" :value="type">Christmas donations</option>
        <option value="7" :value="type">Pay per-category</option>
        <option value="8" :value="type">Pay per-content subscription</option>
        <option value="9" :value="type">Subscription upgrade</option>
      </select>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-12 mb-3">
      <label for="per-page" class="form-label me-1 mt-2">Per page</label>
      <select id="per-page" class="form-control" v-model="perPage" @click="perPageFilter(perPage)">
        <option value="10" :value="perPage">10</option>
        <option value="20" :value="perPage">20</option>
        <option value="50" :value="perPage">50</option>
      </select>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-12 mb-3">
      <label for="from-date" class="form-label mt-2 me-2">From date</label>
      <input type="datetime-local" id="from-date" class="form-control" placeholder="Name or creator" v-model="fromDate" @input="customDate"/>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-12 mb-3">
      <label for="to-date" class="form-label mt-2 me-2">To date</label>
      <div class="d-flex gap-1">
        <input type="datetime-local" id="to-date" class="form-control" v-model="toDate" @input="customDate"/>
        <button type="button" class="btn btn-dark-purple" @click="searchDate">
          <font-awesome-icon :icon="['fas', 'magnifying-glass']"/>
        </button>
      </div>
    </div>
  <div class="table-responsive container-fluid">
    <h4 class="text-center">{{userName ?? 'John Doe'}} payments</h4>
    <router-link :to="{name: 'SingleUser', params: {id: this.$route.params.id}}">Back to user</router-link>
    <button type="button" class="btn btn-success" @click="exportUserPayments" :disabled="disableExport">
      <span v-if="!disableExport">Export Excel file</span>
      <span v-else><font-awesome-icon icon="fa-solid fa-sync" spin/></span>
    </button>
    <table class="table">
      <thead>
      <tr>
        <th scope="col"># ID</th>
        <th scope="col">Date</th>
        <th scope="col">Type</th>
        <th scope="col">Creator</th>
        <th scope="col">Amount</th>
        <th scope="col">Taxes</th>
        <th scope="col">Status</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="payments.length > 0" v-for="payment in payments">
        <th scope="row">{{payment.id}}</th>
        <td>{{payment.time}}</td>
        <td>{{payment.type}}</td>
        <td>{{payment.channel_name}}</td>
        <td>
          <p>{{payment.cost}} {{payment.currency}}</p>
        </td>
        <td>
          <p>{{payment.collected_fees}} {{payment.currency}}</p>
        </td>
        <td>
          <p v-if="payment.status === 1">
            Recurring
          </p>
          <p v-else-if="payment.status === 2">
            New
          </p>
          <p v-else>
            Undefined
          </p>
        </td>
      </tr>
      <tr v-else-if="loading">
        <td colspan="8" class="text-center"><font-awesome-icon icon="fa-solid fa-sync fa-10x" spin/></td>
      </tr>
      <tr v-else>
        <td colspan="8" class="text-center">No payments</td>
      </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-between" v-if="totalRecords >= 1">
      <div>
        <div class="p-2">
          <p>Total payments: {{ totalRecords }}</p>
        </div>
      </div>
      <div>
        <div class="p-2">
          <nav v-if="totalPages >= 1" aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item" :class="{disabled: currentPage - 1 < 1}">
                <a @click="paginate(currentPage - 1)" class="page-link">Previous</a>
              </li>
              <li class="page-item" :class="{active: 1 === currentPage}">
                <a @click="paginate(1)" class="page-link" :class="{'not-clickable': currentPage === 1}">1</a>
              </li>
              <li class="page-item" v-if="currentPage - 2 > 1">
                <a href="#" class="page-link">...</a>
              </li>
              <li class="page-item" v-if="currentPage - 2 > 1">
                <a @click="paginate(currentPage - 2)" class="page-link">{{ currentPage - 2}}</a>
              </li>
              <li class="page-item" v-if="currentPage - 1 > 1">
                <a @click="paginate(currentPage - 1)" class="page-link">{{ currentPage - 1}}</a>
              </li>
              <li class="page-item" v-if="currentPage !== 1"  :class="{active: currentPage}">
                <a @click="paginate(currentPage)" class="page-link" :class="{'not-clickable': currentPage}">{{currentPage }}</a>
              </li>
              <li class="page-item" v-if="currentPage + 1 <= totalPages">
                <a @click="paginate(currentPage + 1)" class="page-link">{{ currentPage + 1}}</a>
              </li>
              <li class="page-item" v-if="currentPage + 2 <= totalPages">
                <a @click="paginate(currentPage + 2)" class="page-link">{{ currentPage + 2}}</a>
              </li>
              <li class="page-item" v-if="currentPage + 2 < totalPages">
                <a href="#" class="page-link">...</a>
              </li>
              <li class="page-item" v-if="currentPage !== totalPages && currentPage + 2 < totalPages" :class="{active: totalPages === currentPage}">
                <a @click="paginate(totalPages)" class="page-link" :class="{'not-clickable': totalPages === currentPage}">{{totalPages }}</a>
              </li>
              <li class="page-item" :class="{disabled: currentPage + 1 > totalPages}">
                <a @click="paginate(currentPage + 1)" class="page-link">Next</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>


<script>
import axios from 'axios';
import _debounce from "lodash/debounce";

export default {
  data() {
    return {
      loading: true,
      disableExport: false,
      type: '',
      keyword: '',
      fromDate: '',
      toDate: '',
      payments: [],
      userName: '',
      currentPage: 1,
      totalPages: 0,
      totalRecords: 0,
      perPage: 20
    }
  },

  mounted() {
    this.getPayments('', '','', '', 1, 20);
    this.getUserName();
  },

  methods: {
    debounceGetPayments: _debounce(function (keyword, type, fromDate, toDate,  page, perPage) {
      this.loading = true;
      axios.get(`/admin/payments/user/${this.$route.params.id}?keyword=${keyword}&type=${type}&from_date=${fromDate}&to_date=${toDate}&page=${page}&per_page=${perPage}`).then(response => {
        this.payments = response.data.data;
        this.totalPages = response.data.totalPages;
        this.totalRecords = response.data.totalRecords;
        this.loading = false;
      })
          .catch(error => {
            console.log(error);
            this.loading = false;
          });
    }, 500),
    getPayments(keyword, type, fromDate, toDate,  page, perPage) {
      this.debounceGetPayments(keyword, type, fromDate, toDate, page, perPage);
    },
    exportUserPayments() {
      this.disableExport = true;
      axios.get(`/admin/export/user-payments/${this.$route.params.id}`, {
        responseType: 'blob'
      })
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', this.userName + Math.floor(Date.now() / 1000) + '.xlsx');
            document.body.appendChild(link);
            link.click();
            this.disableExport = false;
          })
          .catch(error => {
            console.log(error);
            this.disableExport = false;
          })
    },
    getUserName() {
      axios.get(`/admin/user-name/${this.$route.params.id}`).then(response => {
        this.userName = response.data.data;
      })
          .catch(error => {
            console.log(error);
          });
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    customDate(date) {
      let dateValue = this[date];

      // Check if the input is a valid date
      if (dateValue) {
        // Convert the input to a Date object
        const dateObject = new Date(dateValue);
        this[date] = this.formatDate(dateObject);
      }
    },
    searchDate(date) {
      this.date = date;
      this.getPayments(this.keyword, this.type, this.fromDate, this.toDate, this.currentPage, this.perPage);
    },
    changeType(type) {
      this.type = type;
      this.getPayments(this.keyword, this.type, this.fromDate, this.toDate,  this.currentPage, this.perPage);
    },
    searchKeyword() {
      this.getPayments(this.keyword, this.type, this.fromDate, this.toDate, this.currentPage, this.perPage);
    },
    perPageFilter(perPage) {
      this.perPage = perPage;
      this.getPayments(this.keyword, this.type, this.fromDate, this.toDate, this.currentPage, this.perPage);
    },
    paginate(page) {
      this.currentPage = page;
      this.getPayments(this.keyword, this.type, this.fromDate, this.toDate,  this.currentPage, this.perPage);
    }
  }
}
</script>
