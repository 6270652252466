
<template>
  <div v-if="loading" class="text-center container-fluid" style="font-size: 32px">
    <font-awesome-icon icon="fa-solid fa-sync fa-10x" spin/>
  </div>
  <div v-else>
    <br/>
  <div class="card col-xl-6 col-lg-6 col-md-8 col-sm-12 justify-content-center container-fluid">
    <h5  class="card-title text-center mt-3">Add user-admin role</h5>
    <form @submit.prevent="addUserManegement">
    <div class="card-body">
      <div class="alert alert-success" role="alert" v-if="success" >
        {{ success}}
      </div>
      <div class="mb-3">
        <label for="email" class="form-label">Email address</label>
        <input type="email" class="form-control" id="email" v-model="email" aria-describedby="emailHelp">
        <p v-if="validationErrors" v-for="email in validationErrors.email" class="text-danger">{{ email}}</p>
      </div>
      <div class="mb-3">
        <label for="role" class="form-label">Roles</label>
        <select id="role" class="form-control" v-model="role">
          <option v-for="role in roles" :value="role.id">{{role.name}}</option>
        </select>
      </div>
      <div class="card-footer justify-content-center d-flex flex-wrap gap-1">
        <button type="submit" class="btn btn-primary" :disabled="disable">
          <span v-if="!disable">Submit</span>
          <span v-else><font-awesome-icon icon="fa-solid fa-sync" spin/></span>
        </button>
        <router-link :to="{name: 'UserManagement'}" class="btn btn-warning">
          Go back
        </router-link>
      </div>
    </div>
    </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
export default  {
  components: {FontAwesomeIcon},
  data() {
    return {
      user: {},
      validationErrors: [],
      loading: true,
      disable: false,
      email: '',
      roles: [],
      role: 1,
      success: ''
    }
  },
  methods: {
    fetchRoles() {
      this.loading = true;
      axios.get(`/admin/roles`)
          .then(response => {
            this.roles = response.data.data;
            this.loading = false;
          })
          .catch(error => {
            this.loading = false;
            console.log(error);
          });
    },
    addUserManegement() {
      this.validationErrors = [];
      this.disable = true;
      this.success = '';
      axios.post(`admin/user-management`, {
        'email': this.email,
        'role': this.role
      })
          .then(response => {
            this.success = response.data.message;
            this.disable = false;
            setTimeout(() => this.success = '', 30000);
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.validationErrors = error.response.data.errors;
            }
            this.disable = false;
          });
    },
  },
    mounted() {
      window.scrollTo(0, 0);
      this.fetchRoles();
    },
}
</script>