<template>
  <router-link to="/">
    <img
      class="img-fluid for-light big-logo"
      src="https://contribee.com/cdn/landing-page/logo-header.svg"
      alt=""
    /><img
      class="img-fluid for-dark"
      src="../../assets/images/logo/favicon-32x32.png"
      alt=""
    />
  </router-link>
</template>
<script>

  export default {
    name: 'Logo',
  };
</script>


<style scoped>
.big-logo {
  filter: saturate(0);
  width: 140px;
  margin-top: 3px;

}
</style>
