<template>
  <li class="profile-nav onhover-dropdown pe-0 py-0">
    <div class="media profile-media">
      <img class="b-r-10 profile-picture" :src="profile_picture" alt="Profile picture" />
      <div class="media-body">
        <span>{{ name ?? 'Name Surname' }}</span>
        <p class="mb-0 font-roboto">
          {{ role ?? 'User' }} <i class="middle fa fa-angle-down"></i>
        </p>
      </div>
    </div>
    <ul class="profile-dropdown onhover-show-div">
      <li @click="logout">
        <vue-feather type="log-in"></vue-feather><span>Log out</span>
      </li>
    </ul>
    <div v-if="isLoading" class="loading-overlay">
      <div class="spinner"></div>
    </div>
  </li>
</template>

<script>
import store from '../../store';

export default {
  data() {
    return {
      name: store.state.auth.user.name,
      role: store.state.auth.user.role,
      profile_picture: store.state.auth.user.profile_picture,
      isLoading: false,
    }
  },
  name: 'Profile',
  methods: {
    async logout() {
      this.isLoading = true;
      try {
        await this.$store.dispatch('logout');
      } finally {
        this.isLoading = false;
      }
    }
  },
};
</script>

<style scoped>
.profile-picture {
  width: 35px;
}

.profile-media {
  align-items: center;
}

.media-body {
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 80px;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>