<template>
  <br/>
  <div class="table-responsive container-fluid">
    <h4 class="text-center">{{userName ?? 'John Doe'}} blocked by creators</h4>
    <router-link :to="{name: 'SingleUser', params: {id: this.$route.params.id}}">Back to user</router-link>
    <table class="table">
      <thead>
      <tr>
        <th scope="col"># ID</th>
        <th scope="col">Creator</th>
        <th scope="col">Blocked at</th>
        <th scope="col">Chat ban</th>
        <th scope="col">Comment ban</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="blockedChannels.length > 0" v-for="blockedChannel in blockedChannels">
        <th scope="row">{{blockedChannel.id}}</th>
        <td>{{blockedChannel.channel_name}}</td>
        <td>{{blockedChannel.blocked_at}}</td>
        <td v-if="blockedChannel.comment_ban || blockedChannel.channel_ban">
          <font-awesome-icon :icon="['fas', 'check']" />
        </td>
        <td v-else>
          <font-awesome-icon :icon="['fas', 'x']" />
        </td>
        <td v-if="blockedChannel.chat_ban || blockedChannel.channel_ban">
          <font-awesome-icon :icon="['fas', 'check']" />
        </td>
        <td v-else>
          <font-awesome-icon :icon="['fas', 'x']" />
        </td>
      </tr>
      <tr v-else-if="loading">
        <td colspan="5" class="text-center"><font-awesome-icon icon="fa-solid fa-sync fa-10x" spin/></td>
      </tr>
      <tr v-else>
        <td colspan="5" class="text-center">No blocked creators</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      loading: true,
      blockedChannels: [],
      userName: '',
    }
  },

  mounted() {
    this.getBlockedChannels();
    this.getUserName();
  },

  methods: {
    getBlockedChannels() {
      this.loading = true;
      axios.get(`/admin/users/${this.$route.params.id}/blocked-users`).then(response => {
        this.blockedChannels = response.data.data;
        this.loading = false;
      })
          .catch(error => {
            this.loading = false;
            console.log(error);
          });
    },
    getUserName() {
      axios.get(`/admin/user-name/${this.$route.params.id}`).then(response => {
        this.userName = response.data.data;
      })
          .catch(error => {
            console.log(error);
          });
    },
  }
}
</script>