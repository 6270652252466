import axios from "axios";
import store from "@/store";

const state = {
    user: {
        id: null,
        role: null,
        name: null,
        email: null,
        profile_picture: null,
    },
    token: null,
    validationErrors: [],
};

const mutations = {
    SET_USER(state, user) {
        state.user = user;
    },
    SET_TOKEN(state, token) {
        state.token = token;
    },
    SET_VALIDATION_ERRORS(state, errors) {
        state.validationErrors = errors;
    },
};

const getters = {
    getUser: (state) => state.user,
    getToken: (state) => state.token,
    getValidationErrors: (state) => state.validationErrors,
};

const actions = {
    async login({ commit }, credentials) {
        commit('SET_VALIDATION_ERRORS', []);
        await axios.post('/admin/login', credentials)
            .then((response) => {
                const user  = response.data.user; // Corrected line
                const token = response.data.token;
                commit('SET_USER', user);
                commit('SET_VALIDATION_ERRORS', []);
            })
            .catch((error) => {
                    commit('SET_VALIDATION_ERRORS', error.response.data.errors);
                    throw error;
            });
    },
    async submit2FA({ commit }, credentials) {
        commit('SET_VALIDATION_ERRORS', []);
        await axios.post('/admin/2fa', credentials)
            .then((response) => {
                const user  = response.data.user; // Corrected line
                const token = response.data.token;
                localStorage.setItem('token', token);
                commit('SET_TOKEN', token);
                commit('SET_USER', user);
                commit('SET_VALIDATION_ERRORS', []);
            })
            .catch((error) => {
                commit('SET_VALIDATION_ERRORS', error.response.data.errors);
                throw error;
            });
    },
    async logout({commit}) {
        await axios.post('/admin/logout').then(() => {
                   commit('SET_TOKEN', null);
                   commit('SET_USER', null);
                   localStorage.removeItem('token');
                   window.location.href = '/login';
               })
                   .catch((error) => {
                       console.log(error);
                   });
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
