<template>
  <br/>
  <div v-if="loading" class="text-center" style="font-size: 32px">
    <font-awesome-icon icon="fa-solid fa-sync fa-10x" spin/>
  </div>
  <div v-else class="container">
    <div class="alert alert-success" role="alert" v-if="successMessage">
      {{ successMessage}}
    </div>
    <div class="alert alert-danger" role="alert" v-if="errorMessage">
      {{ errorMessage}}
    </div>
    <div class="row mt-10">
      <div class="col-xl-5">
        <div class="card mt-10">
          <img class="rounded-circle mx-auto mt-3"
               :src="user.profile_picture"
               width="100"
               height="100"
               alt="www.contribee.com">
          <h3 class="text-center mt-2 mb-3">{{ user.name }}</h3>
          <h6 class="text-center" v-if="user.channel_link && user.channel">
            <router-link :to="{name: 'SingleChannel', params: {id: user.channel_id}}" target="_blank">{{ user.channel }}</router-link>
          </h6>
            <span class="text-center">
              Logged in:
              <span class="text-center" v-if="user.google_provider_id !== 'null'">Google</span>
            <span class="text-center" v-else-if="user.facebook_provider_id !== 'null'">Facebook</span>
            <span class="text-center" v-else-if="user.linkedin_provider_id !== 'null'">LinkedIn</span>
            <span class="text-center" v-else>Password</span>
            </span>
        </div>
        <div class="accordion" id="userView">
          <div class="accordion-item">
            <h2 class="accordion-header" id="generalInfoHeading">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#generalInfo" aria-expanded="true" aria-controls="generalInfo">
                General info
              </button>
            </h2>
            <div id="generalInfo" class="accordion-collapse collapse show" aria-labelledby="generalInfoHeading" data-bs-parent="#userview">
              <div class="accordion-body">
                <table style="border-radius: 15px;" class="table table-bordered table-white table-responsive rounded-lg">
                  <tr>
                    <th>Name</th>
                    <td style="word-break: break-all;" class="text-right">{{ user.name }}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td style="word-break: break-all;" class="text-right">{{ user.email }}</td>
                  </tr>
                  <tr>
                    <th>Registered at</th>
                    <td class="text-right">{{ user.created_at }}</td>
                  </tr>
                  <tr>
                    <th>Updated at</th>
                    <td class="text-right">{{ user.updated_at }}</td>
                  </tr>
                  <tr>
                    <th>Last payment at</th>
                    <td class="text-right">{{ user.last_payment_at }}</td>
                  </tr>
                  <tr>
                    <th>Email verified at</th>
                    <td class="text-right">{{ user.email_verified_at }}</td>
                  </tr>
                  <tr>
                    <th>Token (App) last used</th>
                    <td class="text-right">{{ user.token_last_used }}</td>
                  </tr>
                  <tr>
                    <th>Stripe user account</th>
                    <td class="text-right">
                      <a :href="user.stripe_code" target="_blank" class="link-primary p-0">
                        Click here
                      </a>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="userLinksHeading">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#userLinks" aria-expanded="false" aria-controls="userLinks">
                User links
              </button>
            </h2>
            <div id="userLinks" class="accordion-collapse collapse" aria-labelledby="userLinksHeading" data-bs-parent="#userView">
              <div class="accordion-body">
                <table style="border-radius: 15px;" class="table table-bordered table-white table-responsive rounded-lg">
                  <tr>
                    <th colspan="2" class="text-center underline">
                      <router-link style="text-decoration: underline;" :to="{name: 'UserPayments', params:{id: user.id}}">
                        Payments
                      </router-link>
                    </th>
                  </tr>
                  <tr>
                    <th colspan="2" class="text-center">
                      <router-link style="text-decoration: underline;" :to="{name: 'UserPledges', params:{id: user.id}}">
                        User subscriptions
                      </router-link>
                    </th>
                  </tr>
                  <tr>
                    <th colspan="2" class="text-center">
                      <router-link style="text-decoration: underline;" :to="{name: 'UnlockedUserPosts', params:{id: user.id}}">
                        Unlocked posts
                      </router-link>
                    </th>
                  </tr>
                  <tr>
                    <th colspan="2" class="text-center">
                      <router-link style="text-decoration: underline;"
                                   :to="{name: 'UnlockedUserCategories', params:{id: user.id}}">Unlocked categories
                      </router-link>
                    </th>
                  </tr>
                  <tr>
                    <th colspan="2" class="text-center">
                      <router-link style="text-decoration: underline;"
                                   :to="{name: 'BlockedChannels', params:{id: user.id}}">Blocked creators
                      </router-link>
                    </th>
                  </tr>
                  <tr>
                    <th colspan="2" class="text-center">
                      <router-link style="text-decoration: underline;"
                                   :to="{name: 'UserOrders', params:{id: user.id}}">Orders
                      </router-link>
                    </th>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="mailNotificationHeading">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#mailNotification" aria-expanded="false" aria-controls="mailNotification">
                Mail notifications
              </button>
            </h2>
            <div id="mailNotification" class="accordion-collapse collapse" aria-labelledby="mailNotificationHeading" data-bs-parent="#userView">
              <div class="accordion-body">
                <table style="border-radius: 15px;" class="table table-bordered table-white table-responsive rounded-lg">
                  <tr>
                    <th>Subscribers mail notifications</th>
                    <td v-if="user.subscribers_mail_notifications === null || user.subscribers_mail_notifications"
                        class="text-right">
                      <font-awesome-icon :icon="['fas', 'check']"/>
                    </td>
                    <td v-else class="text-right">
                      <font-awesome-icon :icon="['fas', 'x']"/>
                    </td>
                  </tr>
                  <tr>
                    <th>Comments mail notifications</th>
                    <td v-if="user.comments_mail_notifications === null || user.comments_mail_notifications" class="text-right">
                      <font-awesome-icon :icon="['fas', 'check']"/>
                    </td>
                    <td v-else class="text-right">
                      <font-awesome-icon :icon="['fas', 'x']"/>
                    </td>
                  </tr>
                  <tr>
                    <th>Weekly statistics mail notifications</th>
                    <td v-if="user.weekly_statistics_mail_notifications === null || user.weekly_statistics_mail_notifications"
                        class="text-right">
                      <font-awesome-icon :icon="['fas', 'x']"/>
                    </td>
                    <td v-else class="text-right">
                      <font-awesome-icon :icon="['fas', 'x']"/>
                    </td>
                  </tr>
                  <tr>
                    <th>New posts mail notfications</th>
                    <td v-if="user.new_posts_mail_notifications === null || user.new_posts_mail_notifications"
                        class="text-right">
                      <font-awesome-icon :icon="['fas', 'check']"/>
                    </td>
                    <td v-else class="text-right">
                      <font-awesome-icon :icon="['fas', 'x']"/>
                    </td>
                  </tr>
                  <tr>
                    <th>Follow ups mail notifications</th>
                    <td v-if="user.follow_ups_mail_notifications === null || user.follow_ups_mail_notifications"
                        class="text-right">
                      <font-awesome-icon :icon="['fas', 'check']"/>
                    </td>
                    <td v-else class="text-right">
                      <font-awesome-icon :icon="['fas', 'x']"/>
                    </td>
                  </tr>
                  <tr>
                    <th>Chat messages mail notifications</th>
                    <td v-if="user.chat_messages_mail_notifications === null || user.chat_messages_mail_notifications"
                        class="text-right">
                      <font-awesome-icon :icon="['fas', 'check']"/>
                    </td>
                    <td v-else class="text-right">
                      <font-awesome-icon :icon="['fas', 'x']"/>
                    </td>
                  </tr>
                  <tr>
                    <th>Replies mail notifications</th>
                    <td v-if="user.replies_mail_notifications === null || user.replies_mail_notifications" class="text-right">
                      <font-awesome-icon :icon="['fas', 'check']"/>
                    </td>
                    <td v-else class="text-right">
                      <font-awesome-icon :icon="['fas', 'x']"/>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="accordion-item" v-if="loginUserRole !== 'Ukrainian Manager'">
            <h2 class="accordion-header" id="userDetailedInformationHeader">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#userDetailedInformatio" aria-expanded="false" aria-controls="userDetailedInformatio">
                Detailed information
              </button>
            </h2>
            <div id="userDetailedInformatio" class="accordion-collapse collapse" aria-labelledby="userDetailedInformationHeader" data-bs-parent="#userView">
              <div class="accordion-body">
                <table style="border-radius: 15px;" class="table table-bordered table-white table-responsive rounded-lg">
                  <tr>
                    <th>Succeded subscription count</th>
                    <td style="word-break: break-all;" class="text-right">{{ userDetailed.succeeded_subscribers_count }}</td>
                  </tr>
                  <tr>
                    <th>Cancelled subscription count</th>
                    <td style="word-break: break-all;" class="text-right">{{ userDetailed.cancelled_subscribers_count }}</td>
                  </tr>
                  <tr>
                    <th>Failed subscription count</th>
                    <td class="text-right">{{ userDetailed.failed_subscribers_count }}</td>
                  </tr>
                  <tr>
                    <th>Expired subscription count</th>
                    <td class="text-right">{{ userDetailed.expired_subscribers_count }}</td>
                  </tr>
                  <tr>
                    <th>PPC subscription count</th>
                    <td class="text-right">{{ userDetailed.ppc_pledges_count }}</td>
                  </tr>
                  <tr>
                    <th>Stripe customer count</th>
                    <td class="text-right">{{ userDetailed.stripe_customer_count }}</td>
                  </tr>
                  <tr>
                    <th>Comments count</th>
                    <td class="text-right">{{ userDetailed.comments_count }}</td>
                  </tr>
                  <tr>
                    <th>Delete user</th>
                    <td class="text-right">
                      <button @click="deleteUser(user.name, user.email)" class="btn btn-danger text-white">
                        Click here to delete
                      </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <br/>
      </div>
      <div class="col-xl-7">
        <div class="row">
          <div class="button-group" role="group">
            <a @click="filterDate('today')" type="button" class="btn btn-dark-purple mb-3 me-2">Today</a>
            <a @click="filterDate('thisMonth')" type="button" class="btn btn-dark-purple mb-3 me-2">This month</a>
            <a @click="filterDate('thisYear')" type="button" class="btn btn-dark-purple mb-3 me-2">This year</a>
            <a @click="filterDate('total')" type="button" class="btn btn-dark-purple mb-3 me-2">Total</a>
            <button type="button" class="btn btn-dark-purple mb-3 me-2" data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop">
              Custom
            </button>
          </div>
          <br/>
          <p v-if="statsLoading"><font-awesome-icon icon="fa-solid fa-sync fa-10x" spin/></p>
          <p v-else>Date filter {{ fromDate }} - {{ toDate }}</p>
          <div v-for="(card, index) in cards" :key="index" class="col-lg-4 col-lg-6">
            <div class="card">
              <div class="card-body">
                <h6 class="card-title">{{ card.title }}</h6>
                <p class="card-text" v-if="card.title !== 'Total payments'">
                  {{ card.value }} {{ selectedCurrencySymbol }}
                </p>
                <p class="card-text" v-else-if="card.title === 'Total payments'">
                  {{ card.value }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br/>
  </div>
  <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
       aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="staticBackdropLabel">Date filter form</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label for="fromDate" class="form-label">From date</label>
            <input type="date" class="form-control me-1" id="fromDate" v-model="fromDate"
                   @input="formatCustomDate('fromDate')">
          </div>
          <div class="mb-3">
            <label for="toDate" class="form-label">To date</label>
            <input type="date" class="form-control me-1" id="toDate" v-model="toDate"
                   @input="formatCustomDate('toDate')">
          </div>
          <div class="mb-3">
            <label for="currency" class="form-label">Currency</label>
            <select class="form-control" id="currency" v-model="selectedCurrency">
              <option v-for="currency in currencies" :value="currency.currency">{{ currency.currency }}</option>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="fetchUserStats(fromDate, toDate, selectedCurrency)" type="button" class="btn btn-dark-purple me-2">
            Search
          </button>
          <a @click="clearFilter" type="button" class="btn btn-dark-purple">Clear</a>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios'
import Notifications from "@/components/header/notifications.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import SingleChannel from "@/pages/dashboard/channels/SingleChannel.vue";
import Swal from "sweetalert2";
import store from "@/store";

export default {
  computed: {
    SingleChannel() {
      return SingleChannel
    }
  },
  components: {FontAwesomeIcon, Notifications},
  data() {
    return {
      user: {},
      userDetailed: {},
      cards: [
        {
          title: 'Revenue',
          value: 0, //this is full value
        },
        {
          title: 'Earnings',
          value: 0,
        },
        {
          title: 'Total payments',
          value: 0,
        },
        {
          title: 'Average spending price',
          value: 0,
        }
      ],
      currencies: [],
      fromDate: '',
      toDate: '',
      selectedCurrency: 'EUR',
      selectedCurrencySymbol: '€',
      loading: true,
      statsLoading: true,
      successMessage: '',
      errorMessage: '',
      loginUserRole: store.state.auth.user.role,
    }
  },
  methods: {
    filterDate(filterParam) {
      const date = new Date();
      if (filterParam === 'today') {
        this.fromDate = new Date();
        this.toDate = new Date();
        this.fromDate = this.fromDate.toISOString().split('T')[0];
        this.toDate = this.toDate.toISOString().split('T')[0];
      } else if (filterParam === 'thisYear') {
        this.fromDate = new Date(date.getFullYear(), 0, 1);
        this.toDate = new Date(date.getFullYear(), 11, 31);
        this.fromDate = this.formatDate(this.fromDate);
        this.toDate = this.formatDate(this.toDate);
      } else if (filterParam === 'thisMonth') {
        this.fromDate = new Date(date.getFullYear(), date.getMonth(), 1);
        this.toDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.fromDate = this.formatDate(this.fromDate);
        this.toDate = this.formatDate(this.toDate);
      } else if (filterParam === 'total') {
        this.fromDate = new Date(2018, 0, 1);
        this.fromDate = this.formatDate(this.fromDate);
        this.toDate = this.formatDate(date);
      }
      this.fetchUserStats(this.fromDate, this.toDate, this.selectedCurrency);
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    formatCustomDate(date) {
      let dateValue = this[date];

      // Check if the input is a valid date
      if (dateValue) {
        // Convert the input to a Date object
        const dateObject = new Date(dateValue);
        this[date] = this.formatDate(dateObject);
      }
    },
    clearFilter() {
      this.selectedCurrency = 'EUR';
      this.selectedCurrencySymbol = '€';
      this.filterDate('thisMonth');
      this.fetchUserStats(this.fromDate, this.toDate, this.selectedCurrency);
    },
    fetchUser() {
      this.loading = true;
      axios.get(`/admin/users/${this.$route.params.id}`)
        .then(response => {
          this.user = response.data;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    fetchUserDetailed() {
      this.loading = true;
      axios.get(`/admin/detailed/user?id=${this.$route.params.id}`)
        .then(response => {
          this.userDetailed = response.data.user;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    fetchUserStats(fromDate, toDate, currency) {
      this.statsLoading = true;
      if (fromDate > toDate) {
        [this.toDate, this.fromDate] = [this.fromDate, this.toDate];
        [toDate, fromDate] = [fromDate, toDate];
      }
      axios.get(`/admin/dashboard/user/${this.$route.params.id}?from_date=${fromDate}&to_date=${toDate}&currency=${currency}`)
          .then(response => {
            this.cards[1].value = response.data.platformEarnings;
            this.cards[0].value = response.data.platformRevenue;
            this.cards[2].value = response.data.totalRecords;
            this.cards[3].value = response.data.costAverage;
            this.statsLoading = false;
          })
          .catch(error => {
            this.statsLoading = false;
            console.log(error);
          })
    },
    fetchCurrencies() {
      axios.get(`/admin/currency/${this.$route.params.id}`)
          .then(response => {
            this.currencies = response.data.data;
          })
          .catch(error => {
            console.log(error);
          });
    },
    async deleteUser(name, email) {
      const confirmed = await Swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to delete this user ${name}?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, proceed',
        cancelButtonText: 'Cancel'
      });
      if (confirmed.isConfirmed) {
        this.loading = true;
        await axios.delete(`admin/delete/user/info`, {
            data: {
              'email': email
            }
          })
          .then(response => {
            if(response.data.errors_occurred.length !== 0) {
              this.errorMessage = 'Errors accured durning process: ' + response.data.errors_occurred;
            }
            this.successMessage = response.data.message;
            this.fetchUser();
            this.fetchUserDetailed();
          })
          .catch(error => {
            if(error.response && error.response.data) {
              this.errorMessage = error.response.data;
            }
            else {
              this.errorMessage = error;
            }
          })
          .finally(response => {
            this.loading = false;
          })
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.fetchUser();
    this.fetchUserDetailed();
    this.filterDate('thisMonth');
    this.fetchUserStats(this.fromDate, this.toDate, this.selectedCurrency);
    this.fetchCurrencies();
  }
}

</script>

