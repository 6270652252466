<template>
  <br/>
  <div class="row p-3">
  <div class="col-lg-4 col-md-6 col-sm-12 mb-3">
    <label for="extractMonth" class="form-label">From date</label>
    <input type="month" min="2021-01" :max="maxMonth" class="form-control me-1" id="extractMonth" v-model="fromDate">
  </div>
  <div class="col-lg-4 col-md-6 col-sm-12 mb-3">
    <label for="extractMonth" class="form-label">To date</label>
    <input type="month" min="2021-01" :max="maxMonth" class="form-control me-1" id="extractMonth" v-model="toDate">
  </div>
  <div class="col-12 mb-3 mt-2">
    <input id="vat" class="form-check-input me-1"  type="checkbox" v-model="vat">
    <label for="vat" class="form-label">
      VAT include
    </label>
  </div>
  <div class="col-lg-4 col-md-6 col-sm-12 mb-3">
    <button @click="exportAccounting(fromDate, toDate, vat)" type="button" class="btn btn-dark-purple" :disabled="disableExport">
      <span v-if="!disableExport">Export Accounting file</span>
      <span v-else><font-awesome-icon icon="fa-solid fa-sync" spin/></span>
    </button>
  </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      disableExport: false,
      fromDate: '',
      toDate: '',
      maxMonth: new Date().getFullYear() + '-' + new Date().getMonth() + 1,
      vat: false
    }
  },
  methods: {
    exportAccounting(fromDate, toDate, vat) {
      if (fromDate > toDate) {
        [this.toDate, this.fromDate] = [this.fromDate, this.toDate];
        [toDate, fromDate] = [fromDate, toDate];
      }

      //make fromDate with fist day of month and toDate with last day of month
      const date = new Date(toDate + '-01');
      date.setMonth(date.getMonth() + 1, 0);
      const lastDayOfMonth = date.getDate();
      fromDate = fromDate + '-01';
      toDate = toDate + '-' + lastDayOfMonth;

      axios.post(`/admin/accounting/payments`, {
        'start_date': fromDate,
        'end_date': toDate,
        'vat': vat,
      }, {
        responseType: 'blob'
      })
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            fromDate = fromDate.replace('-', '');
            toDate = toDate.replace('-', '');
            link.href = url;
            link.setAttribute('download', 'payments'+ fromDate +'-'+toDate+'.csv');
            document.body.appendChild(link);
            link.click();
            this.disableExport = false;
          })
          .catch(error => {
            this.disableExport = false;
            console.log(error);
          });
    },
  }
}
</script>