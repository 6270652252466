<template>
  <br/>
  <div class="table-responsive container-fluid">
    <h4 class="text-center">{{userName ?? 'John Doe'}} unlocked categories</h4>
    <router-link :to="{name: 'SingleUser', params: {id: this.$route.params.id}}">Back to user</router-link>
    <table class="table">
      <thead>
      <tr>
        <th scope="col"># ID</th>
        <th scope="col">Creator</th>
        <th scope="col">Category</th>
        <th scope="col">Unlocked at</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="unlockedCategories.length > 0" v-for="unlockedCategory in unlockedCategories">
        <th scope="row">{{unlockedCategory.id}}</th>
        <td>{{unlockedCategory.channel.channel.name}}</td>
        <td>{{unlockedCategory.name}}</td>
        <td>{{unlockedCategory.created_at}}</td>
      </tr>
      <tr v-else-if="loading">
        <td colspan="4" class="text-center"><font-awesome-icon icon="fa-solid fa-sync fa-10x" spin/></td>
      </tr>
      <tr v-else>
        <td colspan="4" class="text-center">No unlocked categories</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      loading: true,
      unlockedCategories: [],
      userName: '',
    }
  },

  mounted() {
    this.getCategories();
    this.getUserName();
  },

  methods: {
    getCategories() {
      this.loading = true;
      axios.get(`/admin/unlocked-categories/user/${this.$route.params.id}`).then(response => {
        this.unlockedCategories = response.data.data;
        this.loading = false;
      })
          .catch(error => {
            this.loading = false;
            console.log(error);
          });
    },
    getUserName() {
      axios.get(`/admin/user-name/${this.$route.params.id}`).then(response => {
        this.userName = response.data.data;
      })
          .catch(error => {
            console.log(error);
          });
    },
  }
}
</script>