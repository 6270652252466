<template>
  <br/>
  <div class="table-responsive container-fluid">
    <table class="table">
      <thead>
      <tr>
        <th scope="col"># ID</th>
        <th scope="col">Title</th>
        <th scope="col">Admin/user name</th>
        <th scope="col">Admin/user email</th>
        <th scope="col">Old/new payload</th>
        <th scope="col">Created at</th>
      </tr>
      </thead>
      <tbody>
      <tr @click="readMore" v-if="logs.length > 0" v-for="log in logs">
        <th scope="row">{{log.id}}</th>
        <td>{{log.title}}</td>
        <td>{{log.user}}</td>
        <td>{{log.user_email}}</td>
        <td>
          <div class="read-less">
            <Diff
            mode="unified"
            theme="light"
            language="json"
            :prev="log.old_payload"
            :current="log.new_payload"
        />
          </div>
        </td>
        <td>{{log.created_at}}</td>
      </tr>
      <tr v-else-if="loading">
        <td colspan="6" class="text-center"><font-awesome-icon icon="fa-solid fa-sync fa-10x" spin/></td>
      </tr>
      <tr v-else>
        <td colspan="6" class="text-center">No logs</td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-between" v-if="totalRecords >= 1">
    <div>
      <div class="p-2">
        <p>Total logs: {{ totalRecords }}</p>
      </div>
    </div>
    <div>
      <div class="p-2">
        <nav v-if="totalPages >= 1" aria-label="Page navigation example">
          <ul class="pagination">
            <li class="page-item" :class="{disabled: currentPage - 1 < 1}">
              <a @click="paginate(currentPage - 1)" class="page-link">Previous</a>
            </li>
            <li class="page-item" :class="{active: 1 === currentPage}">
              <a @click="paginate(1)" class="page-link" :class="{'not-clickable': currentPage === 1}">1</a>
            </li>
            <li class="page-item" v-if="currentPage - 2 > 1">
              <a href="#" class="page-link">...</a>
            </li>
            <li class="page-item" v-if="currentPage - 2 > 1">
              <a @click="paginate(currentPage - 2)" class="page-link">{{ currentPage - 2 }}</a>
            </li>
            <li class="page-item" v-if="currentPage - 1 > 1">
              <a @click="paginate(currentPage - 1)" class="page-link">{{ currentPage - 1 }}</a>
            </li>
            <li class="page-item" v-if="currentPage !== 1" :class="{active: currentPage}">
              <a @click="paginate(currentPage)" class="page-link"
                 :class="{'not-clickable': currentPage}">{{ currentPage }}</a>
            </li>
            <li class="page-item" v-if="currentPage + 1 <= totalPages">
              <a @click="paginate(currentPage + 1)" class="page-link">{{ currentPage + 1 }}</a>
            </li>
            <li class="page-item" v-if="currentPage + 2 <= totalPages">
              <a @click="paginate(currentPage + 2)" class="page-link">{{ currentPage + 2 }}</a>
            </li>
            <li class="page-item" v-if="currentPage + 2 < totalPages">
              <a href="#" class="page-link">...</a>
            </li>
            <li class="page-item" v-if="currentPage !== totalPages && currentPage + 2 < totalPages"
                :class="{active: totalPages === currentPage}">
              <a @click="paginate(totalPages)" class="page-link"
                 :class="{'not-clickable': totalPages === currentPage}">{{ totalPages }}</a>
            </li>
            <li class="page-item" :class="{disabled: currentPage + 1 > totalPages}">
              <a @click="paginate(currentPage + 1)" class="page-link">Next</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import _debounce from "lodash/debounce";


export default {
  data() {
    return {
      loading: true,
      logs: [],
      keyword: '',
      currentPage: 1,
      totalPages: 0,
      totalRecords: 0,
      perPage: 20,
    }
  },

  mounted() {
    this.getLogs('', 1, 20);
  },

  methods: {
    debounceGetLogs: _debounce(function (keyword, page, perPage)  {
      this.loading = true;
      axios.get(`/admin/logs?keyword=${keyword}&page=${page}&per_page=${perPage}`).then(response => {
        this.logs = response.data.data;
        this.totalPages = response.data.totalPages;
        this.totalRecords = response.data.totalRecords;
        this.loading = false;
      })
          .catch(error => {
            this.loading = false;
            console.log(error);
          });
    }, 500),
    getLogs(keyword, page, perPage) {
      this.debounceGetLogs(keyword, page, perPage);
    },
    perPageFilter(perPage) {
      this.perPage = perPage;
      this.getLogs(this.keyword, this.currentPage, this.perPage);
    },
    paginate(page) {
      this.currentPage = page;
      this.getLogs(this.keyword, this.currentPage, this.perPage);
    },
    readMore(event) {
      const element = event.currentTarget.querySelector('.read-less');
      element.classList.toggle('expanded');
    }
  }
}
</script>