<template>
    <div class="row login-block">
      <div class="login mt-5">
        <div class="card">
          <div class="card-header">
            <img class="contribee-logo mx-auto d-block" src="https://contribee.com/cdn/landing-page/logo-header.svg" alt="contribee">
          </div>
          <div class="card-body">
            <form method="post">
              <div class="d-grid gap-3">
                <div class="form-group">
                  <label for="email">Email address</label>
                  <input
                      type="email"
                      class="form-control"
                      id="email"
                      placeholder="Enter email"
                      v-model="email"
                  />
                  <p v-if="validationErrors" v-for="emails in validationErrors.email" class="text-danger">{{ emails}}</p>
                </div>
                <div class="form-group">
                  <label for="password">Password</label>
                  <input
                      class="form-control"
                      type="password"
                      placeholder="Password"
                      v-model="password"
                  />
                  <p v-if="validationErrors" v-for="passwords in validationErrors.password" class="text-danger">{{ passwords}}</p>
                  <p v-if="validationErrors.invalid" class="text-danger">{{ validationErrors.invalid}}</p>
                  <p v-if="validationErrors.permission" class="text-danger">{{ validationErrors.permission}}</p>
                </div>
                <button type="button" @click="login" class="btn btn-primary login-btn" :disabled="disableLogin">
                  <span v-if="!disableLogin">Login</span>
                  <span v-else><font-awesome-icon icon="fa-solid fa-sync" spin/></span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade famodalbackground" id="2faModal" tabindex="-1" aria-labelledby="2faModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div style="border: none;" class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="2faModalLabel">2FA form</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <label for="reason" class="form-label me-2 mt-2">2FA code</label>
          <input minlength="6" maxlength="6" id="reason" class="form-control" placeholder="123456" v-model="tfa_code"/>
          <p v-if="validationErrors.tfa_code" class="text-danger">
            {{ validationErrors.tfa_code }}
          </p>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" @click="submit2FA" class="btn btn-primary login-btn" :disabled="disable2FA">
          <span v-if="!disable2FA">Submit</span>
          <span v-else><font-awesome-icon icon="fa-solid fa-sync" spin/></span>
        </button>
      </div>
    </div>
  </div>
</div>

</template>

<script>
import store from '../../store';
import { Modal } from 'bootstrap'; // Ensure you have imported Bootstrap's Modal

export default {
  data() {
    return {
      email: '',
      password: '',
      tfa_code: '',
      validationErrors: [],
      disableLogin: false,
      disable2FA: false,
      modalInstance: null, // Add a property to hold the modal instance
    };
  },
  methods: {
    async login() {
      this.validationErrors = [];
      this.disableLogin = true;
      try {
        await this.$store.dispatch('login', {
          email: this.email,
          password: this.password,
        });
        this.showModal('2faModal');
        this.disableLogin = false;
      } catch (error) {
        console.log(error);
        this.disableLogin = false;
      }
    },
    async submit2FA() {
      this.validationErrors = [];
      this.disable2FA = true;
      try {
        await this.$store.dispatch('submit2FA', {
          tfa_code: this.tfa_code,
          user_id: store.state.auth.user.id,
          email: this.email,
          password: this.password
        });
        this.closeModal('2faModal');
        if (store.state.auth.user.role === 'Ukrainian Manager') {
          this.$router.push({ name: 'UkrainianHome' });
        } else {
          this.$router.push({ name: 'Home' });
        }
        this.disable2FA = false;
      } catch (error) {
        console.log(error);
        this.disable2FA = false;
      }
    },
    showModal(modalId) {
      const modalElement = document.getElementById(modalId);
      this.modalInstance = new Modal(modalElement);
      this.modalInstance.show();
    },
    closeModal(modalId) {
      if (this.modalInstance) {
        this.modalInstance.hide();
      }
    }
  },
  computed: {
    validationErrors() {
      return this.$store.state.auth.validationErrors;
    },
  },
};
</script>

<style scoped>

.famodalbackground {

    background-color: rgb(0, 0, 0, 0.1);
    backdrop-filter: blur(2px);
}


</style>