<template>
  <div class="page-404">
    <h4 class="text-center">Record not found</h4>
    <a class="back-btn-styling" @click="back">Back to previous page</a>
  </div>
</template>

<script>
export default {
  methods:{
    back() {
      this.$router.go(-2);
    }
  }
}
</script>

<style scoped>

.page-404 {


  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 15px;

  border-radius: 15px;
  max-width: 500px;
  margin: 100px auto;
  padding: 100px;

  box-shadow: 9px 9px 20px 9px rgba(46, 35, 94, 0.07);


}

.back-btn-styling {

  background-color: #3f1c67;
  border-color: #3f1c67;
  color: white;
  padding: 0.375rem 1.75rem;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  margin: 0px auto;

}
</style>