<template>
  <br/>
  <div style="padding:5px"  class="row">
    <div class="col-lg-6 col-md-12 mb-3">
        <label for="search" class="form-label mt-2 me-2">Search</label>
        <input id="search" class="form-control" placeholder="Name or email or id" v-model="keyword" @input="searchKeyword"/>
    </div>
    <div class="col-lg-6 col-md-12 mb-3">
      <label for="per-page" class="form-label me-2 mt-2">Per page</label>
      <select id="per-page" class="form-control" v-model="perPage" @click="perPageFilter(perPage)">
        <option value="10" :value="perPage">10</option>
        <option value="20" :value="perPage">20</option>
        <option value="50" :value="perPage">50</option>
      </select>
      </div>
  </div>
<div class="table-responsive container-fluid d-grid gap-3">
<table class="table">
  <thead>
  <tr>
    <th scope="col"># ID</th>
    <th scope="col">Full name</th>
    <th scope="col">E-mail</th>
    <th scope="col">Registered at</th>
    <th scope="col">Email verified at</th>
    <th scope="col">Actions</th>
  </tr>
  </thead>
  <tbody>
  <tr v-if="users.length > 0" v-for="user in users">
    <th scope="row">{{user.id}}</th>
    <td><router-link :to="{name: 'SingleUser', params: {id: user.id }}">{{user.name}}</router-link></td>
    <td>{{user.email}}</td>
    <td>{{user.created_at}}</td>
    <td>{{user.email_verified_at}}</td>
    <td>
      <router-link :class="{disabled: !approvedRoles.includes(role)}" :to="{name: 'EditUser', params: {id: user.id }}" class="btn btn-warning">
        Edit
      </router-link>
    </td>
  </tr>
  <tr v-else-if="loading">
    <td colspan="6" class="text-center"><font-awesome-icon icon="fa-solid fa-sync fa-10x" spin/></td>
  </tr>
  <tr v-else>
    <td colspan="6" class="text-center">No users</td>
  </tr>
  </tbody>
</table>
  <div class="d-flex justify-content-between" v-if="totalRecords >= 1">
    <div>
      <div class="p-2">
        <p>Total users: {{ totalRecords }}</p>
      </div>
    </div>
    <div>
      <div class="p-2">
        <nav v-if="totalPages >= 1" aria-label="Page navigation example">
          <ul class="pagination">
            <li class="page-item" :class="{disabled: currentPage - 1 < 1}">
              <a @click="paginate(currentPage - 1)" class="page-link">Previous</a>
            </li>
            <li class="page-item" :class="{active: 1 === currentPage}">
              <a @click="paginate(1)" class="page-link" :class="{'not-clickable': currentPage === 1}">1</a>
            </li>
            <li v-if="currentPage - 2 > 1" class="page-item">
              <a href="#" class="page-link">...</a>
            </li>
            <li class="page-item" v-if="currentPage - 2 > 1">
              <a @click="paginate(currentPage - 2)" class="page-link">{{ currentPage - 2}}</a>
            </li>
            <li class="page-item" v-if="currentPage - 1 > 1">
              <a @click="paginate(currentPage - 1)" class="page-link">{{ currentPage - 1}}</a>
            </li>
            <li class="page-item" v-if="currentPage !== 1"  :class="{active: currentPage}">
              <a @click="paginate(currentPage)" class="page-link" :class="{'not-clickable': currentPage}">{{currentPage }}</a>
            </li>
            <li class="page-item" v-if="currentPage + 1 <= totalPages">
              <a @click="paginate(currentPage + 1)" class="page-link">{{ currentPage + 1}}</a>
            </li>
            <li class="page-item" v-if="currentPage + 2 <= totalPages">
              <a @click="paginate(currentPage + 2)" class="page-link">{{ currentPage + 2}}</a>
            </li>
            <li class="page-item" v-if="currentPage + 2 < totalPages">
              <a href="#" class="page-link">...</a>
            </li>
            <li class="page-item" v-if="currentPage !== totalPages && currentPage + 2 < totalPages" :class="{active: totalPages === currentPage}">
              <a @click="paginate(totalPages)" class="page-link" :class="{'not-clickable': totalPages === currentPage}">{{totalPages }}</a>
            </li>
            <li class="page-item" :class="{disabled: currentPage + 1 > totalPages}">
              <a @click="paginate(currentPage + 1)" class="page-link">Next</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>
<!-- </div> -->
</template>


<script>
import axios from 'axios';
import _debounce from "lodash/debounce";
import store from "../../../store";

export default {
  data() {
    return {
      approvedRoles: ['Admin', 'Super Admin'],
      loading: true,
      users: [],
      keyword: '',
      currentPage: 1,
      totalPages: 0,
      totalRecords: 0,
      perPage: 20,
      role: store.state.auth.user.role
    }
  },
  created() {
    this.getUsers('', 1,20);
  },
  methods: {
    debounceGetUsers: _debounce(function (keyword, page, perPage) {
      this.loading = true;
      axios.get(`/admin/users?keyword=${keyword}&page=${page}&per_page=${perPage}`).then(response => {
        this.users = response.data.data;
        this.totalPages = response.data.totalPages;
        this.totalRecords = response.data.totalRecords;
        this.loading = false;
      })
          .catch(error => {
            this.loading = false;
            console.log(error);
          });
    }, 500),
    getUsers(keyword, page, perPage) {
      this.debounceGetUsers(keyword, page, perPage);
    },
    searchKeyword() {
      this.getUsers(this.keyword,this.currentPage, this.perPage);
    },
    perPageFilter(perPage) {
      this.perPage = perPage;
      this.getUsers(this.keyword,this.currentPage, this.perPage);
    },
    paginate(page) {
      this.currentPage = page;
      this.getUsers(this.keyword,this.currentPage, this.perPage);
    }
  }
}
</script>