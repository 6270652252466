<template>
  <br/>
  <div class="row">
    <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
        <label for="search" class="form-label me-2 mt-2">Search</label>
        <input id="search" class="form-control" placeholder="Name, slug, email or description" v-model="keyword" @input="searchKeyword"/>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
        <label for="per-page" class="form-label me-2 mt-2">Per page</label>
        <select id="per-page" class="form-control" v-model="perPage" @click="perPageFilter(perPage)">
          <option value="10" :value="perPage">10</option>
          <option value="20" :value="perPage">20</option>
          <option value="50" :value="perPage">50</option>
        </select>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
        <label for="country" class="form-label me-2 mt-2">Country</label>
        <select id="country" class="form-control" v-model="selectedCountry" @click="changeCountry(selectedCountry)">
          <option value="" :value="selectedCountry">All</option>
          <option v-for="country in countries" :value="country.country">{{country.country}}</option>
        </select>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
        <label for="status" class="form-label me-2 mt-2">Status</label>
        <select id="status" class="form-control" v-model="status" @click="changeStatus(status)">
          <option value="" :value="status">All</option>
          <option value="awaiting_confirmation" :value="status">Awaiting confirmation</option>
          <option value="pending_check" :value="status">Pending check</option>
          <option value="OK" :value="status">OK</option>
          <option value="denied" :value="status">Denied</option>
        </select>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
      <label for="category" class="form-label me-2 mt-2">Content category</label>
      <select class="form-control" id="category" v-model="category" @click="changeCategory(category)">
        <option value="0" :value="category">
          All
        </option>
        <option v-for="contentCategory in contentCategories" :value="contentCategory.id">
          {{contentCategory.name}}
        </option>
      </select>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
      <label for="adult" class="form-label me-2 mt-2">Adult content</label>
      <select class="form-control" id="adult" v-model="adult" @click="changeAdult(adult)">
        <option value="" :value="adult">All</option>
        <option value="true" :value="adult">Yes</option>
        <option value="false" :value="adult">No</option>
      </select>
    </div>
    <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3">
      <label class="form-label mt-2 me-2">Export creators</label>
      <button type="button" class="btn btn-success form-control" :disabled="disableExport" @click="confirmExport()">
        <span v-if="!disableExport">Export Excel file</span>
        <span v-else><font-awesome-icon icon="fa-solid fa-sync" spin/></span>
      </button>
    </div>
  </div>
  <div class="table-responsive">
    <div class="alert alert-success" role="alert" v-if="success">
      {{ success}}
    </div>
    <div class="alert alert-danger" role="alert" v-if="errorConfirm">
      {{ errorConfirm}}
    </div>
    <table class="table">
      <thead>
      <tr>
        <th scope="col"># ID</th>
        <th scope="col">Creator name, slug</th>
        <th scope="col">Email</th>
        <th scope="col">Country</th>
        <th scope="col">Status</th>
        <th scope="col">Registered at</th>
        <th scope="col">Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="channels.length > 0" v-for="channel in channels">
        <th scope="row">{{channel.id}}</th>
        <td>
          <img :src="channel.profile_picture" alt="No photo" class="profile-photo"/>
          <p>{{channel.name}}</p>
          <a :href="channel.link">{{channel.slug}}</a>
        </td>
        <td>{{channel.email}}</td>
        <td>{{channel.country}}</td>
        <td>
          <span v-if="channel.status === 'awaiting_confirmation'" class="badge badge-info">
            <font-awesome-icon icon="fa-solid fa-rotate" />
            Awaiting from Stripe
          </span>
          <span v-else-if="channel.status === 'OK'" class="badge badge-success">
            <font-awesome-icon icon="fas fa-check-circle"/>
            Activated
          </span>
          <span v-else-if="channel.status === 'pending_check'" class="badge badge-info">
            <font-awesome-icon icon="fa-solid fa-rotate"/>
            Pending from Admin
          </span>
          <span v-else-if="channel.status === 'denied'" class="badge badge-danger">
            <font-awesome-icon icon="fa-solid fa-circle-xmark"/>
            Denied
          </span>
          <span v-else class="badge badge-secondary">
            Undefined
          </span>
          <br/>
          <span v-if="channel.frozen">
          <font-awesome-icon icon="fa-solid fa-circle-xmark"/>
          Restricted
          </span>
        </td>
        <td>{{channel.created_at}}</td>
        <td>
          <div class="btn-group-sm d-flex gap-3" role="group">
            <router-link :to="{name: 'SingleChannel', params: {id: channel.id}}" class="btn btn-info">View</router-link>
            <router-link :to="{name: 'EditChannel', params: {id: channel.id}}" class="btn btn-warning">Edit</router-link>
            <button v-if="channel.status === 'pending_check' || channel.status === 'denied'" type="button" class="btn btn-success" :disabled="disableConfirm"
                    @click="confirmChannel(channel.id, channel.name)">
              <span v-if="!disableConfirm">Confirm</span>
              <span v-else><font-awesome-icon icon="fa-solid fa-sync" spin/></span>
            </button>
            <button v-if="channel.status === 'pending_check'" type="button" class="btn btn-danger" :disabled="disableDeny"
                    @click="denyChannel(channel.id, channel.name)">
              <span v-if="!disableDeny">Deny</span>
              <span v-else><font-awesome-icon icon="fa-solid fa-sync" spin/></span>
            </button>
          </div>
        </td>
      </tr>
      <tr v-else-if="loading">
        <td colspan="7" class="text-center"><font-awesome-icon icon="fa-solid fa-sync" spin/></td>
      </tr>
      <tr v-else>
        <td colspan="7" class="text-center">No creators</td>
      </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-between" v-if="totalRecords >= 1">
      <div>
        <div class="p-2">
          <p>Total creators: {{ totalRecords }}</p>
        </div>
      </div>
      <div>
        <div class="p-2">
          <nav v-if="totalPages >= 1" aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item" :class="{disabled: currentPage - 1 < 1}">
                <a @click="paginate(currentPage - 1)" class="page-link">Previous</a>
              </li>
              <li class="page-item" :class="{active: 1 === currentPage}">
                <a @click="paginate(1)" class="page-link" :class="{'not-clickable': currentPage === 1}">1</a>
              </li>
              <li class="page-item" v-if="currentPage - 2 > 1">
                <a href="#" class="page-link">...</a>
              </li>
              <li class="page-item" v-if="currentPage - 2 > 1">
                <a @click="paginate(currentPage - 2)" class="page-link">{{ currentPage - 2}}</a>
              </li>
              <li class="page-item" v-if="currentPage - 1 > 1">
                <a @click="paginate(currentPage - 1)" class="page-link">{{ currentPage - 1}}</a>
              </li>
              <li class="page-item" v-if="currentPage !== 1"  :class="{active: currentPage}">
                <a @click="paginate(currentPage)" class="page-link" :class="{'not-clickable': currentPage}">{{currentPage }}</a>
              </li>
              <li class="page-item" v-if="currentPage + 1 <= totalPages">
                <a @click="paginate(currentPage + 1)" class="page-link">{{ currentPage + 1}}</a>
              </li>
              <li class="page-item" v-if="currentPage + 2 <= totalPages">
                <a @click="paginate(currentPage + 2)" class="page-link">{{ currentPage + 2}}</a>
              </li>
              <li class="page-item" v-if="currentPage + 2 < totalPages">
                <a href="#" class="page-link">...</a>
              </li>
              <li class="page-item" v-if="currentPage !== totalPages && currentPage + 2 < totalPages" :class="{active: totalPages === currentPage}">
                <a @click="paginate(totalPages)" class="page-link" :class="{'not-clickable': totalPages === currentPage}">{{totalPages }}</a>
              </li>
              <li class="page-item" :class="{disabled: currentPage + 1 > totalPages}">
                <a @click="paginate(currentPage + 1)" class="page-link">Next</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import _debounce from "lodash/debounce";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      loading: true,
      disableExport: false,
      channels: [],
      countries: [],
      errorConfirm: '',
      selectedCountry: '',
      status: '',
      category: 0,
      adult: '',
      contentCategories: [],
      success: '',
      keyword: '',
      currentPage: 1,
      totalPages: 0,
      totalRecords: 0,
      perPage: 20,
      disableConfirm: false,
      disableDeny: false
    }
  },

  mounted() {
    let status = this.$route.query.status ?? '';
    this.status = this.$route.query.status ?? '';
    this.success = this.$route.query.channel_deleted ? 'Creator and their subscriptions were deleted successfully' : '';
    this.fetchContentCategories();
    this.getChannels(1, 20, '', status, 0, '', '');
    this.getCountries();
  },

  methods: {
    getCountries() {
      this.loading = true;
      axios.get(`/admin/channel/countries`).then(response => {
        this.countries = response.data.countries;
        this.loading = false;
      })
          .catch(error => {
            this.loading = false;
            console.log(error);
          });
    },
    debounceGetChannels: _debounce(function (page, perPage, country, status, category, keyword, adult) {
      this.loading = true;
      axios.get(`/admin/channels?page=${page}&per_page=${perPage}&country=${country}&status=${status}&category=${category}&adult=${adult}&keyword=${keyword}`)
          .then(response => {
        this.channels = response.data.data;
        this.totalPages = response.data.totalPages;
        this.totalRecords = response.data.totalRecords;
        this.loading = false;
      })
          .catch(error => {
            this.loading = false;
            console.log(error);
          });
    }, 500),
    getChannels(page, perPage, country, status, category, keyword, adult) {
      this.debounceGetChannels(page, perPage, country, status, category, adult, keyword);
    },
    changeCountry(country) {
      this.selectedCountry = country;
      this.getChannels(this.currentPage, this.perPage, this.selectedCountry, this.status, this.category, this.adult, this.keyword);
    },
    changeStatus(status) {
      this.status = status;
      this.getChannels(this.currentPage, this.perPage, this.selectedCountry, this.status, this.category, this.adult, this.keyword);
    },
    changeCategory(category) {
      this.category = category;
      this.getChannels(this.currentPage, this.perPage, this.selectedCountry, this.status, this.category, this.adult, this.keyword);
    },
    changeAdult(adult) {
      this.adult = adult;
      this.getChannels(this.currentPage, this.perPage, this.selectedCountry, this.status, this.category, this.adult, this.keyword);
    },
    searchKeyword() {
      this.getChannels(this.currentPage, this.perPage, this.selectedCountry, this.status, this.category, this.adult, this.keyword);
    },
    perPageFilter(perPage) {
      this.perPage = perPage;
      this.getChannels(this.currentPage, this.perPage, this.selectedCountry, this.status, this.category, this.adult, this.keyword);
    },
    paginate(page) {
      this.currentPage = page;
      this.getChannels(this.currentPage, this.perPage, this.selectedCountry, this.status, this.category, this.adult, this.keyword);
    },
    fetchContentCategories() {
      axios.get(`/admin/content-categories`)
          .then(response => {
            this.contentCategories = response.data;
          })
          .catch(error => {
            console.log(error);
          });
    },
    async confirmChannel(channel, name) {
      const confirmed = await Swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to confirm this creator ${name}?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, proceed',
        cancelButtonText: 'Cancel'
      });
      if (confirmed.isConfirmed) {
        this.success = '';
        this.error = '';
        this.reason = '';
        this.disableConfirm = true;
        await axios.put(`admin/channel/confirm/${channel}`)
            .then(response => {
              this.success = response.data.message;
              this.disableConfirm = false;
              this.getChannels(1, 20, '', 'pending_check','');
              setTimeout(() => this.success = '', 60000);
            })
            .catch(error => {
              if (error.response.status === 422) {
                this.errorConfirm = error.response.data.message;
                this.disableConfirm = false;
                setTimeout(() => this.error = '', 60000);
              }
            })
      }
    },
    async denyChannel(channel, name) {
      const confirmed = await Swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to perform this operation ${name}?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, proceed',
        cancelButtonText: 'Cancel'
      });
      if (confirmed.isConfirmed) {
        this.success = '';
        this.disableDeny = true;
        axios.put(`admin/channel/deny/${channel}`)
            .then(response => {
              this.success = response.data.message;
              this.disableDeny = false;
              this.getChannels(1, 20, '', 'pending_check','');
              setTimeout(() => this.success = '', 60000);
            })
            .catch(error => {
              this.disableDeny = false;
            })
      }
    },
    confirmExport() {
      Swal.fire({
        title: "Do you want to export filtered creators ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Export",
        cancelButtonColor: "#d33",
      }).then((result) => {
        if (result.isConfirmed) {
          this.exportChannels();
        } else if (result.isDenied) {

        }
      });
    },
    exportChannels() {
      this.disableExport = true;
      axios.post(`/admin/export/channel-information`, {
        'keyword': this.keyword,
        'country': this.selectedCountry,
        'status': this.status,
        'category': this.category,
        'adult' : this.adult
      }, {
        responseType: 'blob'
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'channels-' + Math.floor(Date.now() / 1000) + '.xlsx');
        document.body.appendChild(link);
        link.click();
        this.disableExport = false;
      })
      .catch(error => {
        this.disableExport = false;
        console.log(error);
      });
    }
  }
}
</script>
